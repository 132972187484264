
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import ICheckbox from '../../../shared/general/interfaces/ICheckbox'
import BaseIcon from '../BaseIcon.vue'

@Component({
  name: 'BaseCheckbox',
  components: { BaseIcon },
})
export default class BaseCheckbox extends Vue {
  cValue : boolean = false

  cIndeterminate : boolean = false

  cDisabled : boolean = false

  @Prop() label ?: string

  @Prop({ default: false }) value! : boolean

  @Prop({ default: false }) indeterminate! : boolean

  @Prop({ default: false }) disabled! : boolean

  @Prop({ default: false }) error! : boolean

  @Prop() message ?: string

  @Prop() id ?: string

  created () {
    this.cValue = this.value
    this.cIndeterminate = this.indeterminate
    this.cDisabled = this.disabled
  }

  @Watch('value')
  onValueChange (val : boolean) {
    this.cValue = val
  }

  @Watch('indeterminate')
  onIndeterminateChange (val : boolean) {
    this.cIndeterminate = val
  }

  @Watch('disabled')
  onDisabledChange (val : boolean) {
    this.cDisabled = val
  }

  @Emit('click')
  click (event : UIEvent) : ICheckbox {
    event.preventDefault()
    event.stopPropagation()

    if (this.cDisabled) return { value: this.cValue, indeterminate: this.cIndeterminate, disabled: this.cDisabled }

    // Always jump from indeterminate to true
    this.cValue = this.cIndeterminate ? true : !this.cValue
    this.cIndeterminate = false

    return {
      value: this.cValue,
      indeterminate: this.cIndeterminate,
      disabled: this.cDisabled,
    }
  }

  get focusStyle () : string {
    if (this.cDisabled) return ''
    if (this.error) return 'focus:outline-none focus:ring focus:ring-red-400 focus-visible:outline-none'
    return 'focus:outline-none focus:ring focus:ring-darkgreen focus-visible:outline-none'
  }

  get boxStyle () : string {
    if (this.cDisabled) return 'bg-gray-100 border-gray-300'
    if (this.cIndeterminate) return 'bg-white border-gray-300'
    if (this.cValue) return 'bg-darkgreen border-darkgreen'
    if (this.error) return 'border-red'

    return 'bg-white border-gray-300'
  }

  get checkmarkColor () : string {
    const colors = {
      darkgreen: 'text-darkgreen',
      white: 'text-white',
      grayDark: 'text-gray-300',
      grayLight: 'text-gray-100',
    }

    if (this.cDisabled && (this.cValue || this.indeterminate)) return colors.grayDark
    if (this.cDisabled && !this.cValue) return colors.grayLight
    if (this.cIndeterminate) return colors.darkgreen
    return colors.white
  }

  get checkmarkPosition () : string {
    if (this.cIndeterminate) return 'left-1/2 -translate-x-1/2'
    return ''
  }

  get checkmarkIcon () : string {
    if (this.cIndeterminate) return 'minus'
    return 'check'
  }
}
