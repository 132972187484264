
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection, FSXARichText as RichText } from 'fsxa-pattern-library'
import { File, RichTextElement } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BaseSearchInput from '../../base/form/BaseSearchInput.vue'
import { globalLabel } from '../../../shared/general/services/StoreService'
import { Logger } from '../../../shared/general/logger/Logger'
import { TDrsData, TDrsLabels } from '../../../shared/general/types/TDrs'

interface IPayload {
  st_label_result ?: string
  st_placeholder_search_input ?: string
  st_label_mechanics ?: string
  st_label_bodywork ?: string
  st_label_paintwork ?: string
  st_label_car ?: string
  st_label_truck ?: string
  st_text ?: RichTextElement[]
  st_csv ?: File
}

@Component({
  name: 'StDrs',
  components: {
    RichText,
    BaseSearchInput,
    BaseGridLayout,
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
    Drs: () => import('../../drs/Drs.vue'),
  },
})
export default class StDrs extends FSXABaseSection<IPayload> {
  private drsData : TDrsData[] = []

  private showNoResults : boolean = false

  private showLoadingAnimation : boolean = false

  private get noSearchResultsFound () : string | RichTextElement[] {
    return globalLabel('no_search_results_found_label')
  }

  private get placeholderSearchInput () : string | undefined {
    return this.payload.st_placeholder_search_input
  }

  private get additionalText () : RichTextElement[] {
    return this.payload.st_text || []
  }

  private get drsLabels () : TDrsLabels {
    const {
      st_label_result: results = '',
      st_label_mechanics: mechanics = '',
      st_label_bodywork: bodywork = '',
      st_label_paintwork: paintwork = '',
      st_label_car: car = '',
      st_label_truck: truck = '',
    } = this.payload || {}
    return {
      results, mechanics, bodywork, paintwork, car, truck,
    }
  }

  private get host () : string {
    return process.client ? window.location.origin : 'http://localhost:3000'
  }

  private async fetchData (zipCode : string) : Promise<TDrsData[]> {
    const { id, url, fileMetaData } = this.payload.st_csv || {}
    if (!id || !url || !(fileMetaData?.extension === 'csv')) return []
    try {
      return (await fetch(`${this.host}/api/drs`, {
        method: 'POST',
        body: JSON.stringify({ id, url, zipCode }),
      })).json()
    } catch (error) {
      Logger.error(error)
    }
    return []
  }

  private async filterDrsData (zipCode : string) : Promise<void> {
    const matches : RegExpMatchArray | null = zipCode.match(/^[0-9]{5}$/)
    if (!matches?.length) {
      this.showNoResults = true
      return
    }
    this.showLoadingAnimation = true
    this.drsData = await this.fetchData(zipCode)
    this.showLoadingAnimation = false
    this.showNoResults = !this.drsData.length
  }
}
