
import { FSXABaseAppLayout, FSXAGetters, FSXARichText } from 'fsxa-pattern-library'
import {
  Component, ProvideReactive, Vue, Watch,
} from 'nuxt-property-decorator'
import { CommitField } from 'gitlog'
import {
  Option, ProjectProperties, RichTextElement, Section,
} from 'fsxa-api'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import TheSnackbar from '../TheSnackbar.vue'
import TheCookieBanner from '../TheCookieBanner.vue'
import TheHeader from '../header/TheHeader.vue'
import TheFooter from '../footer/TheFooter.vue'
import IReference from '../../shared/fsxa/interfaces/IReference'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import IBreadcrumbItem from '../../shared/general/interfaces/IBreadcrumbItem'
import { IGeoTargetingInfo } from '../layouts/TheGeoTargeting.vue'
import IOption from '../../shared/general/interfaces/IOption'
import ISocialShareBarLinkListEntryData from '../../shared/general/interfaces/ISocialShareBarLinkListEntryData'
import TrackingContainer from '../tracking/TrackingContainer.vue'
import getLinkObject from '../../shared/fsxa/services/LinkService'
import ImageVideoModalContent from '../ImageVideo/ImageVideoModalContent.vue'
import LightboxCarousel from '../LightboxCarousel.vue'
import { ILink } from '../../shared/general/interfaces/ILink'
import { trackPI, paramTracking } from '../../shared/general/tracking/TrackingService'
import BaseImageVideo from '../ImageVideo/BaseImageVideo.vue'
import StoreSingleton from '../../shared/general/services/StoreSingleton'
import { globalLabelAsString, langToFsxaLocale } from '../../shared/general/services/StoreService'
import Paragraph from './richtext/Paragraph.vue'
import { fetchFilteredPrivacyKeys } from '../../shared/general/services/privacy/PrivacySettingsService'
import { scrollToSection } from '../../shared/general/services/AnchorLinkService'
import LocationSearch from '../LocationSearch/LocationSearch.vue'
import { Logger } from '../../shared/general/logger/Logger'
import { nuxt } from '../../shared/general/logger/LogKey'
import BaseIcon from '../base/BaseIcon.vue'
import { getIndexPageUrl } from '../../middleware/RedirectsHandler'
import MessageBar from '../MessageBar.vue'
import BaseButton from '../base/BaseButton.vue'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import fetchMaintenanceData from '../../shared/fsxa/services/MaintenanceService'
import { caasLocale } from '../../shared/general/services/LanguageService'
import BaseLink from '../base/BaseLink.vue'

interface IIdMapElement {
  caasDocumentId : string
  contentReference : string
  customData ?: string
  id : string
  label : string
  parentIds : string[]
  permissions ?: string
  seoRoute : string
  seoRouteRegex ?: string
}

interface IIdMap {
  [key : string] : IIdMapElement
}

interface INavDatStructEl {
  id : string
  children : INavDatStructEl[]
}

interface IFooterLinkData {
  lt_link : string | IReference
  lt_target : boolean
  lt_text : string
  lt_title_text ?: string
  lt_link_params ?: string
}

interface IFooterNavData {
  id : string
  type : string
  sectionType : string
  previewId : string
  data : IFooterLinkData
}

interface ISocialMediaType {
  key : string
  value : string
}

interface ISocialMediaLinkData {
  lt_link : string
  lt_social_media_type : ISocialMediaType
  lt_target : boolean
  lt_whatsapp_phone_number ?: number
}

interface ISocialMediaFooterNavData {
  id : string
  type : string
  sectionType : string
  previewId : string
  data : ISocialMediaLinkData
}

interface ITrackingToolData {
  data ?: {
    st_trackingId ?: string
    st_trackingTool ?: {
      key ?: string
    }
  }
}

interface ILanguageRoute {
  locale : string
  seoRoute : string
}

@Component({
  name: 'AppLayout',
  components: {
    BaseLink,
    BaseButton,
    MessageBar,
    BaseIcon,
    TheSnackbar,
    TheCookieBanner,
    TheHeader,
    TheFooter,
    TrackingContainer,
    ImageVideoModalContent, // used in modal component
    LightboxCarousel, // used in modal component
    LocationSearch, // used in sidebar component
    BaseImageVideo,
    TheModal: () => import('../layouts/TheModal.vue'),
    TheSidebar: () => import('../layouts/TheSidebar.vue'),
    TheGeoTargeting: () => import('../layouts/TheGeoTargeting.vue'),
    TheSocialShareBar: () => import('../layouts/TheSocialShareBar.vue'),
  },
})
export default class AppLayout extends FSXABaseAppLayout {
  @ProvideReactive('triggerRouteChange') triggerRouteChange_ : Function = this.triggerRouteChange

  @ProvideReactive('getUrlByPageId') getUrlByPageId_ : Function = this.getUrlByPageId

  @ProvideReactive('globalSettings') globalSettings_ : ProjectProperties | null = null

  private readonly mainContentId : string = 'main-content'

  private appInsights : ApplicationInsights | undefined = undefined

  private ready : boolean = true

  private piTracked : boolean = false

  private showGeoTargeting : boolean = false

  private geoTargetingInfo : IGeoTargetingInfo | false = false

  private maintenanceBarButtonVariant : EButtonVariant = EButtonVariant.NeutralOutline

  private headerWrapperSize = 88 // Base height of header. It was hard coded before and because Nuxt2 will be shut down anyways, I kept it

  private lastScroll : number = 0

  private darkLayerVisible = false

  $refs! : {
    header ?: Vue
    maintenanceBar ?: Vue
  }

  async created () {
    StoreSingleton.init(this.$store)
    this.globalSettings_ = this.globalSettings
    this.$store.commit('Locales/set', this.languages)

    if (process.client) {
      window.addEventListener('titleUpdated', (event : Event) => {
        if (this.piTracked) return
        this.piTracked = true
        trackPI((event as CustomEvent).detail.title)
      })
    }

    const locale = this.localeFromURL
    if (locale && locale.toUpperCase() !== this.locale.toUpperCase()) {
      await this.languageChange(locale)
    }

    const { pt_homepage: homepage, pt_contact_page: contactPage } = this.globalSettings?.data.ps_header ?? {}
    if (homepage) this.$store.commit('PageHeader/setHomepage', this.getUrlByPageId(homepage.referenceId))
    if (contactPage) this.$store.commit('PageHeader/setContactPage', this.getUrlByPageId(contactPage.referenceId))

    this.storeSiteIdentifier()
    this.storePath()
    this.setInitialPrivacySettings()
    this.logBuildIndicator()
    this.setParamTracking()
  }

  async mounted () {
    await this.processMaintenanceData()
    this.$store.commit('Viewport/set', window.innerWidth)

    window.addEventListener('resize', () => {
      this.$store.commit('Viewport/set', window.innerWidth)
    })

    this.checkGeoTargeting()
    this.getCampaignParameter()

    // browser back/forward button language change
    window.onpopstate = () => {
      // 1 is the language part in our URLs (/de/whatever-else -> ['' , 'de', 'whatever-else'])
      const lang = window.location.pathname.split('/')[1]
      const localeFromURL = langToFsxaLocale(lang)

      if (!localeFromURL || localeFromURL === this.locale) return

      this.$store.commit('Locale/set', localeFromURL)
      this.languageChange(localeFromURL)
    }

    this.$nextTick(() => {
      const headerWrapper = document.querySelector('.header-wrapper')
      this.headerWrapperSize = headerWrapper?.clientHeight || 88
      this.animateHeaderOnScroll(headerWrapper as HTMLElement)

      const { hash } = window.location
      if (!hash) return

      scrollToSection(hash)
    })
  }

  private async checkGeoTargeting () : Promise<void> {
    // check if we have to show the geo targeting layer
    // if geoTargeting is present and has either the value no-redirect or redirect we don't show the layer

    const geoTargeting = localStorage.getItem('geoTargeting')
    if (geoTargeting === 'no-redirect') return
    if (this.$route.path !== await getIndexPageUrl(this.fsxaApi, this.$store.state.Locale.locale)) return

    this.geoTargetingInfo = this.getGeoTargetingInfo()

    if (!this.geoTargetingInfo.matchedCountry || !this.geoTargetingInfo.goToLink) return

    if (geoTargeting === 'redirect') {
      // redirect the user to the given country URL
      window.location.href = this.geoTargetingInfo.goToLink
      return
    }

    // if the user is in a country which we redirect to
    this.showGeoTargeting = !!this.geoTargetingInfo.matchedCountry
  }

  private async setInitialPrivacySettings () : Promise<void> {
    if (!process.client) return

    const ls = JSON.parse(localStorage.getItem('privacySettings') || '{}')
    if (!Object.keys(ls).length) {
      const keysToSet = await fetchFilteredPrivacyKeys(
        this.globalSettings?.data?.ps_country_project_settings?.pt_default_cookies || [],
        this.locale,
      )
      const vendors = keysToSet.map((key) => ({
        key,
        value: true,
      }))

      this.$store.commit('PrivacySettings/setStateAll', vendors)
      return
    }

    const vendors = Object.entries(ls).map(([key, value]) => ({
      key,
      value: value === 'true',
    }))
    this.$store.commit('PrivacySettings/setStateAll', vendors)
  }

  private async processMaintenanceData () : Promise<void> {
    if (this.$store.state.Page.maintenanceBarVisible) return
    const { activate, text } = await fetchMaintenanceData(this.$store.state.Locale.fsxaLocale)
    if (!activate || !text.length) return

    this.$store.commit('Page/setMaintenanceBar', {
      visible: sessionStorage.getItem('show-maintenance-bar') !== 'false',
      text,
    })
  }

  private async getCampaignParameter () : Promise<boolean> {
    try {
      const urlParams : URLSearchParams = new URLSearchParams(window.location.search)
      const locationId = urlParams.get('locationId') || ''
      urlParams.delete('locationId')
      await this.$store.dispatch('Locations/setCampaignLocationId', {
        locationId,
        locale: this.locale,
      })

      const pathname = urlParams.toString()
        ? `${window.location.pathname}?${urlParams.toString()}`
        : window.location.pathname

      window.history.replaceState({}, document.title, pathname + window.location.hash)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }

    return true
  }

  async serverPrefetch () {
    this.$store.commit('PageRefMapping/set', this.$config.FSXA_REMOTE_DATASET_PAGEREF_MAPPING)
    this.$store.commit('Locales/set', this.languages)
    this.$store.commit('Locale/set', this.localeFromURL)
    await this.$store.dispatch('GlobalLabels/fetchForLanguage', this.localeFromURL || process.env.FSXA_LOCALE || 'en_US')
  }

  private async languageChange (locale : string) : Promise<void> {
    this.$store.commit('Locale/set', locale)
    await this.$store.dispatch('GlobalLabels/fetchForLanguage', this.$store.state.Locale.fsxaLocale)

    if (process.client) document.body.classList.remove('cb-visible')

    try {
      await this.triggerRouteChange({ locale: this.$store.state.Locale.fsxaLocale })
    } finally {
      const { pt_homepage: homepage, pt_contact_page: contactPage } = this.globalSettings?.data.ps_header ?? {}
      if (homepage) this.$store.commit('PageHeader/setHomepage', this.getUrlByPageId(homepage.referenceId))
      if (contactPage) this.$store.commit('PageHeader/setContactPage', this.getUrlByPageId(contactPage.referenceId))

      this.ready = false
      await this.$nextTick()
      this.ready = true

      this.$store.commit('PrivacySettings/reset')
      await this.setInitialPrivacySettings()
      // trigger cookie banner resize
      setTimeout(() => {
        if (process.client) window.dispatchEvent(new CustomEvent('resize'))
      }, 500)
    }
  }

  private async selectLanguage (language : IOption) : Promise<void> {
    const errorPage = `${window.location.protocol}//${window.location.host}/error-page`
    const locale = language.value as string

    try {
      const languagePagesRaw = await fetch('/api/route-for-languages', {
        method: 'POST',
        body: JSON.stringify({
          id: this.getCurrentPageId(this.currentPage?.contentReference),
          datasetId: this.currentDataset?.id ?? '',
          localeList: this.languages.map((lang) => caasLocale(lang.value as string)),
        }),
      })

      if (languagePagesRaw.status !== 200) {
        Logger.error(nuxt, 'Could not load page in other languages. Redirect to /error-page', languagePagesRaw.text())
        window.location.href = errorPage
        return
      }

      const languagePages : ILanguageRoute[] = await languagePagesRaw.json()
      const route = languagePages.find((page : ILanguageRoute) => page.locale === caasLocale(locale))
      if (route) {
        window.location.href = `${window.location.protocol}//${window.location.host}${route.seoRoute}`
        return
      }

      window.location.href = errorPage
    } catch (e) {
      Logger.error(nuxt, 'An unknown error occurred. Redirect to /error-page', e)
      window.location.href = errorPage
    }
  }

  private animateHeaderOnScroll (headerWrapper : HTMLElement | null) : void {
    if (!headerWrapper) return
    window.addEventListener('scroll', () => this.scrollListener(headerWrapper))
  }

  private scrollListener (headerWrapper : HTMLElement) : void {
    const currentScroll = window.scrollY

    if (this.isSafariBrowser()) {
      // Workaround for Safari bug when scrolling to the top
      if ((window.innerHeight + window.scrollY) < window.innerHeight) {
        return
      }

      // Workaround for Safari bug when scrolling to the bottom
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        return
      }
    }

    const header = headerWrapper.querySelector('#header')
    if (!header) return

    if (!this.darkLayerVisible && currentScroll > this.lastScroll && currentScroll > 88) {
      headerWrapper.style.transform = `translateY(-${header.clientHeight}px)`
    }

    if (currentScroll < this.lastScroll) {
      headerWrapper.style.transform = 'translateY(0px)'
    }

    this.lastScroll = currentScroll
  }

  private getCurrentPageId (contentReference ?: string | null | undefined) {
    if (!contentReference) return ''
    if (contentReference.lastIndexOf('/') === -1) return ''
    if (contentReference.lastIndexOf('.') === -1) return ''
    return contentReference.slice(contentReference.lastIndexOf('/') + 1, contentReference.lastIndexOf('.'))
  }

  head () {
    return {
      htmlAttrs: {
        lang: this.localeFromURL.split('_')?.[0] || 'en',
      },
    }
  }

  private get skipToMainContentLabel () : string {
    return globalLabelAsString('skip_to_main_content_label')
  }

  private get maintenanceBarText () : string | RichTextElement[] {
    return this.$store.state.Page.maintenanceBarText
  }

  private get maintenanceBarVisible () : boolean {
    return this.$store.state.Page.maintenanceBarVisible
  }

  private get isNavigationOpen () : boolean {
    return this.$store.state.Page.isNavigationOpen
  }

  private get cookieBannerHeadline () : string {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_headline || ''
  }

  private get cookieBannerText () : FSXARichText[] | undefined {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_text?.map(
      (text : Paragraph) => text?.content,
    )
  }

  private get cookieBannerLink () : ILink {
    return getLinkObject(
      this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_link,
      this.getUrlByPageId,
    )
  }

  private get showCookieRejectAllButton () : boolean {
    return !!this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_buttons?.some(
      (button : Option) => button?.key === 'reject_all',
    )
  }

  private get showCookieAcceptAllButton () : boolean {
    return !!this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_buttons?.some(
      (button : Option) => button?.key === 'accept_all',
    )
  }

  private get showCookieOkayButton () : boolean {
    return !!this.globalSettings?.data?.ps_country_project_settings?.pt_cookie_banner_buttons?.some(
      (button : Option) => button?.key === 'okay',
    )
  }

  private get modal () : Record<string, string> {
    return {
      open: this.$store.state.ModalContent.isOpen,
      component: this.$store.state.ModalContent.component,
      props: this.$store.state.ModalContent.props,
    }
  }

  private get sidebar () : Record<string, string> {
    return {
      component: this.$store.state.SidebarContent.component,
      props: this.$store.state.SidebarContent.props,
    }
  }

  private get languages () : IOption[] {
    const currentLocaleFirst = (option : IOption) => (
      option.value.toString().toLowerCase() === this.locale.toLowerCase() ? -1 : 1
    )
    const languages : { key : string, value : string }[] = this.globalSettings?.data?.ps_language || []
    return languages.map(({ key, value }) => {
      const upperCaseKey = key.toUpperCase()
      const localeReplacement = this.$config?.FSXA_LOCALE_REPLACEMENT

      return {
        value: localeReplacement?.[upperCaseKey]
          ? `${localeReplacement[upperCaseKey].language}_${localeReplacement[upperCaseKey].country}`
          : `${key}`,
        label: value,
      }
    }).sort(currentLocaleFirst)
  }

  private get headerNavElements () : IHeaderNavElement[] {
    const getChildren = (item : INavDatStructEl, lvl : number) : IHeaderNavElement[] => item.children?.map((child) => {
      const ret : IHeaderNavElement = {
        key: this.idMap[child.id]?.caasDocumentId,
        path: this.getUrlByPageId(child.id) || '#',
        label: this.idMap[child.id]?.label,
      }

      // Cut off everything after level 4
      // I know, 2 is a strange number for this, but it works.
      // Source: Dude, trust me (it's because we start way later with counting, as level 1 and 2 are already there)
      if (child.children && child.children.length > 0 && lvl < 2) {
        ret.children = getChildren(child, lvl + 1)
      }

      return ret
    }) || []

    return this.structure.map<IHeaderNavElement>((item : INavDatStructEl) => ({
      key: this.idMap[item.id]?.caasDocumentId,
      label: this.idMap[item.id]?.label,
      path: this.getUrlByPageId(item.id) || '#',
      children: getChildren(item, 0),
    })) || []
  }

  private get footerNavElements () : ILink[] {
    return this.globalSettings?.data.ps_footer?.pt_nav_linklist?.map(
      (item : IFooterNavData) => getLinkObject(item, this.getUrlByPageId),
    ) || []
  }

  private get trackingTools () : Record<string, string> {
    const trackingTools = this.globalSettings?.data.ps_country_project_settings?.pt_tracking_tool
    return trackingTools?.reduce((result : Record<string, string>, { data } : ITrackingToolData) => ({
      ...result,
      [data?.st_trackingTool?.key || 'NO_KEY_GIVEN']: data?.st_trackingId,
    }), {})
  }

  private get footerSocialMediaElements () : ILink[] {
    return this.globalSettings?.data.ps_footer?.pt_social_linklist?.map(
      (item : ISocialMediaFooterNavData) => getLinkObject(item, this.getUrlByPageId),
    ) || []
  }

  private get homepageUrl () : string {
    return this.$store.state.PageHeader.homepage
  }

  private get breadcrumbItems () : IBreadcrumbItem[] {
    if (!this.currentPage) return []

    const currentPageElement = this.idMap[this.currentPage.caasDocumentId]
    const { parentIds } = currentPageElement || {}
    if (!parentIds) return []

    const isLastElement = (index : number) => index === parentIds.length - 1
    const breadcrumbs : IBreadcrumbItem[] = [
      ...[{
        key: '0',
        globalLabel: 'homepage_label',
        link: this.homepageUrl,
      }],
      ...parentIds.map((parentId, index) => ({
        key: this.idMap[parentId].caasDocumentId,
        label: this.idMap[parentId].label,
        link: isLastElement(index) ? undefined : this.getUrlByPageId(parentId) || '#',
      }))]
    this.$store.commit('Breadcrumbs/set', breadcrumbs)

    return breadcrumbs
  }

  private get searchPageUrl () : string {
    return this.getUrlByPageId(this.globalSettings?.data?.ps_country_project_settings?.pt_search_page?.referenceId || '') || ''
  }

  private get headerClasses () : string {
    return this.$store.state.ModalContent.isFilterOpen
      ? '!translate-y-[-93px] md:!translate-y-[0] z-50'
      : 'block'
  }

  private get socialShareBarNetworks () : string[] {
    const shareBarLinkList = this.globalSettings
      ?.data?.ps_country_project_settings?.pt_social_share_bar_linklist as ISocialShareBarLinkListEntryData[]

    return shareBarLinkList
      ?.map((link) => link?.data?.lt_social_media_type?.key)
      // TODO: this should be removed as soon as the package "ShareNetwork" supports x.com instead of twitter.com
      .map((type) => (type === 'x' ? 'twitter' : type))
      .filter((key) => !!key)
  }

  private get showSocialShareBar () : boolean {
    return !!this.globalSettings?.data?.ps_country_project_settings?.pt_activate_social_share_bar
      && this.$store.state.SocialSharing.displaySocialShareBar
  }

  private get idMap () : IIdMap {
    return this.$store.getters[FSXAGetters.navigationData]?.idMap || {}
  }

  private get structure () : INavDatStructEl[] {
    return this.$store.getters[FSXAGetters.navigationData]?.structure || []
  }

  private get hasToolbarElements () : boolean {
    return this.$store.state.ToolbarElements.fixedElements.length || this.$store.state.ToolbarElements.elements.length
  }

  private getGeoTargetingInfo () : IGeoTargetingInfo {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

    const matchedCountry = this.globalSettings?.data?.ps_geotargeted_countries
      ?.filter((country : Section) => country?.data?.st_country_iso_code === timeZone)?.[0]

    return {
      matchedCountry,
      goToLabel: matchedCountry?.data?.st_country_label,
      goToLink: matchedCountry?.data?.st_country_link,
    }
  }

  private storePath () : void {
    const path = this.breadcrumbItems.map((item) => item.key)
    this.$store.commit('Navigation/set', path)
  }

  private logBuildIndicator () : void {
    if (this.$config.FSXA_TENANT_ID === 'dekra-prod') return

    const traceLog = (msg : string) => {
      // eslint-disable-next-line no-console
      console.info(msg)
      this.appInsights?.trackTrace({ message: msg })
    }

    // eslint-disable-next-line no-console
    console.group('BUILD INDICATOR')
    traceLog(`RUN_TIMESTAMP: ${new Date(this.$config.RUN_TIMESTAMP).toISOString()}`)
    traceLog(`BUILD_TIMESTAMP: ${new Date(this.$config.BUILD_TIMESTAMP).toISOString()}`)
    traceLog(`BUILD_NUMBER: ${this.$config.BUILD_NUMBER}`)
    traceLog(`PACKAGE_LOCK_HASH: ${this.$config.PACKAGE_LOCK_HASH}`)
    // eslint-disable-next-line no-console
    console.groupCollapsed('FSXA')
    traceLog(`FSXA_API_VERSION: ${this.$config.FSXA_API_VERSION}`)
    traceLog(`FSXA_NUXT_MODULE_VERSION: ${this.$config.FSXA_NUXT_MODULE_VERSION}`)
    traceLog(`FSXA_PATTERN_LIBRARY_VERSION: ${this.$config.FSXA_PATTERN_LIBRARY_VERSION}`)
    // eslint-disable-next-line no-console
    console.groupEnd()
    // eslint-disable-next-line no-console
    console.groupCollapsed('CHANGELOG')
    const gitCommits : {
      [Property in CommitField] : string;
    }[] = this.$config.GIT_COMMITS
    gitCommits.forEach((c) => {
      const {
        hash, committerDate, subject, body,
      } = c
      // eslint-disable-next-line no-console
      console.info(`${hash}\n${new Date(committerDate).toLocaleString()}\n${subject}\n${body}`)
    })
    // eslint-disable-next-line no-console
    console.groupEnd()
    // eslint-disable-next-line no-console
    console.groupEnd()
  }

  private get localeFromURL () : string {
    // 1 is the language part in our URLs (/de/whatever-else -> ['' , 'de', 'whatever-else'])
    const lang = this.$router.currentRoute.fullPath.split('/')[1]
    const locale = langToFsxaLocale(lang)
    return locale || this.locale
  }

  private get maintenanceBarButtonLabel () : string {
    return globalLabelAsString('okay_label')
  }

  private maintenanceBarButtonClick () : void {
    this.$store.commit('Page/resetMaintenanceBar')
    sessionStorage.setItem('show-maintenance-bar', 'false')
  }

  private resetCampaignParameter () : void {
    this.$store.commit('Locations/resetCampaignLocationId')
  }

  private setParamTracking () : void {
    if (!process.client) return

    const params = paramTracking()
    if (Object.keys(params).length) {
      this.$store.commit('Tracking/set', params)
      return
    }
    try {
      const sessionParams = JSON.parse(sessionStorage.getItem('trackingParams') || '{}')
      if (Object.keys(sessionParams).length) {
        this.$store.commit('Tracking/set', sessionParams)
      }
    } catch (e) {
      Logger.warn(nuxt, 'Could not parse tracking params from session storage', e)
    }
  }

  private storeSiteIdentifier () : void {
    const identifier = this.globalSettings?.data.ps_header?.pt_site_identifier_text || ''
    this.$store.commit('PageHeader/setSiteIdentifier', identifier)
  }

  private initAppInsights () : void {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: this.$config?.APPLICATIONINSIGHTS_CONNECTION_STRING,
      },
    })
    try {
      this.appInsights.loadAppInsights()
      this.appInsights.trackPageView()
    } catch (e) {
      Logger.warn(nuxt, 'Could not initialize App Insights | initAppInsights |', e)
    }
  }

  private isSafariBrowser () : boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  private setDarkLayerVisible (isVisible : boolean) : void {
    this.darkLayerVisible = isVisible
  }

  @Watch('modal.open')
  private onModalOpenChange (newVal : boolean) : void {
    const functionToCall = newVal ? 'add' : 'remove'
    document.body.classList[functionToCall]('overflow-hidden')
  }

  @Watch('$route', { immediate: true, deep: true })
  private routeChange () : void {
    this.resetCampaignParameter()
  }

  @Watch('$store.state.PrivacySettings.settings.appInsights')
  private appInsightsChange (appInsightsEnabled : boolean) : void {
    if (!appInsightsEnabled || !!this.appInsights) return

    this.initAppInsights()
  }
}
