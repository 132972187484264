import { Section, RichTextElement } from 'fsxa-api'
import ILocationData from '../interfaces/ILocationData'
import getLinkObject from './LinkService'
import { mapImage } from '../../general/services/ImageService'
import IContactElement from '../../general/interfaces/IContactElement'
import formatStreetAddress from '../../general/services/location/LocationStreetViewOrderService'
import formatPostCodeAddress from '../../general/services/location/LocationPostCodeViewOrderService'
import ELinkTarget from '../../general/enums/ELinkTarget'
import { globalLabelAsString } from '../../general/services/StoreService'
import { isNowDateInDateRange } from '../../general/services/DateTimeService'

function collectLocationTypes (location : ILocationData) : string | undefined {
  if (!location.data?.tt_location_types?.length) return undefined

  return location.data.tt_location_types.map((locationType) => locationType.data.tt_name).join(' / ')
}

export function streetAddress (locationData : ILocationData) : string {
  const streetOrder = locationData?.data?.tt_country?.data?.tt_street_view_order?.identifier ?? '1'
  const { tt_street: street, tt_house_number: houseNumber } = locationData.data ?? {}
  return formatStreetAddress({ street, houseNumber }, streetOrder).join(' ')
}

export function postalAddress (locationData : ILocationData) : string {
  const postCodeViewOrder = locationData.data?.tt_country?.data?.tt_post_code_view_order?.identifier
    ?? '1'
  const { tt_city: city, tt_zip_code: zipCode, tt_state: state } = locationData.data ?? {}
  return formatPostCodeAddress({ city, zipCode, state }, postCodeViewOrder).join(' ')
}

function contactLocations (
  location : ILocationData[] | undefined,
  locationView : Section,
  getUrlByPageId : Function,
  col ?: string,
) : IContactElement[] {
  if (!location || !location.length) return []

  const views : Section[] | undefined = locationView?.data?.st_location_view?.[0]?.data?.tt_config

  // eslint-disable-next-line no-use-before-define
  return createContactArray({
    locationData: location[0],
    locationViews: views,
    getUrlByPageId,
    col,
  })
}

function getRichText (element ?: RichTextElement[]) : RichTextElement[] | undefined {
  return element?.length ? element : undefined
}

export function getAddressString (locationData : ILocationData) : string {
  const {
    tt_street: street,
    tt_house_number: houseNumber,
    tt_zip_code: zipCode,
    tt_city: city,
  } = locationData.data || {}

  return `${street || ''} ${houseNumber || ''}, ${zipCode || ''} ${city || ''}`
}

interface ICreateContactArrayParams {
  locationData : ILocationData
  locationViews : Section[] | undefined
  getUrlByPageId : Function
  col ?: string
  withLocationSearchCtas ?: boolean
}

export default function createContactArray ({
  locationData, locationViews, getUrlByPageId, col, withLocationSearchCtas = false,
} : ICreateContactArrayParams) : IContactElement[] {
  const viewElements : IContactElement[] = locationViews?.reduce((locationViewsAccumulator : IContactElement[], locationView : Section) => {
    const element : IContactElement = {
      type: 'empty',
      data: '',
      col,
    }

    switch (locationView.sectionType) {
      case 'st_location_name':
        element.type = 'boldText'
        element.data = locationData.data.tt_name
        break
      case 'st_location_name_2':
        element.type = 'text'
        element.data = locationData.data.tt_name_2
        break
      case 'st_location_download':
        element.type = 'download'
        element.data = getLinkObject(locationData.data.tt_download, getUrlByPageId)
        break
      case 'st_location_email':
        element.type = 'email'
        element.data = locationData.data.tt_email
        break
      case 'st_location_additional_address':
        element.type = 'text'
        element.data = locationData.data.tt_additional_address
        break
      case 'st_location_city_with_zip':
        element.type = 'text'
        element.data = postalAddress(locationData)
        break
      case 'st_location_country':
        element.type = 'text'
        element.data = locationData.data?.tt_country?.data?.tt_name
        break
      case 'st_location_street':
        element.type = 'text'
        element.data = streetAddress(locationData)
        break
      case 'st_contact_image':
      case 'st_location_image':
        element.type = 'image'
        element.data = mapImage(locationData.data?.tt_image, undefined, undefined, true)
        break
      case 'st_contact_link':
      case 'st_location_link':
        element.type = 'link'
        element.data = getLinkObject(locationData.data.tt_link, getUrlByPageId)
        break
      case 'st_location_link_website':
        if (withLocationSearchCtas) break
        element.type = 'link'
        element.data = { ...getLinkObject(locationData.data.tt_link_website, getUrlByPageId), iconName: 'globe', withoutIcon: false }
        break
      case 'st_location_department_1':
        element.type = 'text'
        element.data = locationData.data.tt_department_1
        break
      case 'st_location_department_1_email':
        element.type = 'email'
        element.data = locationData.data.tt_department_1_email
        break
      case 'st_location_department_1_phone':
        element.type = 'phone'
        element.data = locationData.data.tt_department_1_phone
        break
      case 'st_location_department_2':
        element.type = 'text'
        element.data = locationData.data.tt_department_2
        break
      case 'st_location_department_2_email':
        element.type = 'email'
        element.data = locationData.data.tt_department_2_email
        break
      case 'st_location_department_2_phone':
        element.type = 'phone'
        element.data = locationData.data.tt_department_2_phone
        break
      case 'st_location_opening_hours':
        element.type = 'location'
        element.data = {
          openingHours: locationData.data.tt_opening_hours_areas,
        }
        break
      case 'st_location_link_online_appointment':
        if (withLocationSearchCtas) break
        element.type = 'link'
        element.data = {
          ...getLinkObject(locationData.data.tt_link_online_appointment, getUrlByPageId),
          globalLabelKey: 'online_appointment_label',
          iconName: 'calendar-days',
          withoutIcon: false,
        }
        break
      case 'st_location_types':
        element.type = 'boldText'
        element.data = collectLocationTypes(locationData)
        break
      case 'st_location_link_route_planner': {
        if (withLocationSearchCtas) break
        const addressString = getAddressString(locationData)
        element.type = 'link'
        element.data = {
          url: `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressString)}`,
          globalLabelKey: 'directions_label',
          iconName: 'location-dot',
          target: ELinkTarget.Blank,
          withoutIcon: false,
        }
        break
      }
      case 'st_location_whg_certificates':
        if (withLocationSearchCtas) break
        element.type = 'linkList'
        element.data = locationData.data.tt_whg_certificates_list?.map((listElement) => getLinkObject(listElement, getUrlByPageId)) || []
        break
      case 'st_contact_download_1':
        element.type = 'download'
        element.data = getLinkObject(locationData.data.tt_download_1, getUrlByPageId)
        break
      case 'st_contact_download_2':
        element.type = 'download'
        element.data = getLinkObject(locationData.data.tt_download_2, getUrlByPageId)
        break
      case 'st_contact_download_3':
        element.type = 'download'
        element.data = getLinkObject(locationData.data.tt_download_3, getUrlByPageId)
        break
      case 'st_contact_email_general':
        element.type = 'email'
        element.data = locationData.data.tt_email_general
        break
      case 'st_contact_email_personal':
        element.type = 'email'
        element.data = locationData.data.tt_email_personal
        break
      case 'st_location_fax':
      case 'st_contact_fax':
        element.type = 'fax'
        element.data = locationData.data.tt_fax
        break
      case 'st_contact_free_text_3':
        element.type = 'richText'
        element.data = getRichText(locationData.data.tt_free_text_3)
        break
      case 'st_contact_function_1':
        element.type = 'text'
        element.data = locationData.data.tt_function_1
        break
      case 'st_contact_function_2':
        element.type = 'text'
        element.data = locationData.data.tt_function_2
        break
      case 'st_contact_function_3':
        element.type = 'text'
        element.data = locationData.data.tt_function_3
        break
      case 'st_contact_link_social_1':
        element.type = 'social'
        element.data = getLinkObject(locationData.data.tt_social_link_1, getUrlByPageId)
        break
      case 'st_contact_link_social_2':
        element.type = 'social'
        element.data = getLinkObject(locationData.data.tt_social_link_2, getUrlByPageId)
        break
      case 'st_contact_name':
        element.type = 'boldText'
        element.data = [locationData.data.tt_title, locationData.data.tt_first_name, locationData.data.tt_last_name]
          .filter((e) => !!e).join(' ')
        break
      case 'st_contact_phone_business':
        element.type = 'phone'
        element.data = locationData.data.tt_phone_business
        break
      case 'st_contact_phone_general':
        element.type = 'phone'
        element.data = locationData.data.tt_phone_general
        break
      case 'st_location_phone_mobile':
      case 'st_contact_phone_mobile':
        element.type = 'phone'
        element.data = locationData.data.tt_phone_mobile
        break
      case 'st_location_phone_1':
        element.type = 'phone'
        element.data = locationData.data.tt_phone_1
        break
      case 'st_location_link_opening_hours':
        element.type = 'link'
        element.data = getLinkObject(locationData.data.tt_link_opening_hours, getUrlByPageId)
        break
      case 'st_location_current_information':
        element.type = 'currentInformation'
        element.data = isNowDateInDateRange(locationData.data.tt_current_information_date_from, locationData.data.tt_current_information_date_to)
          ? getRichText(locationData.data.tt_current_information) : undefined
        break
      case 'st_location_services':
        element.type = 'richText'
        element.data = getRichText(locationData.data.tt_services)
        break
      case 'st_contact_free_text_1':
        element.type = 'richText'
        element.data = getRichText(locationData.data.tt_free_text_1)
        break
      case 'st_location_free_text_1':
        element.type = 'freeText1'
        element.data = getRichText(locationData.data.tt_free_text_1)
        break
      case 'st_contact_free_text_2':
      case 'st_location_free_text_2':
        element.type = 'richText'
        element.data = getRichText(locationData.data.tt_free_text_2)
        break
      case 'st_location_view_information': {
        const data = [...(locationData.data.tt_special_services_list || [])]

        // add an extra point for commercial vehicle information to the list
        if (locationData.data.tt_suitable_for_commercial_vehicles) {
          data.push({
            key: '0',
            type: 'Option',
            value: globalLabelAsString('suitable_for_commercial_vehicles_label'),
          })
        }

        element.type = 'list'
        element.data = data
        break
      }
      case 'st_location_whg_fields_of_activity':
        element.type = 'activityText'
        element.data = locationData?.data?.tt_whg_fields_of_activity?.map((fieldOfAct) => fieldOfAct.data?.tt_name || '')
          .filter(Boolean).join(' / ') || ''
        break
      case 'st_contact_location_1':
        locationViewsAccumulator.push(...contactLocations(locationData.data.tt_location_1, locationView, getUrlByPageId, col))
        return locationViewsAccumulator
      case 'st_contact_location_2':
        locationViewsAccumulator.push(...contactLocations(locationData.data.tt_location_2, locationView, getUrlByPageId, col))
        return locationViewsAccumulator
      case 'st_location_distance':
        element.type = 'distance'
        element.data = locationData.data.tt_distance
        break
      case 'st_view_spacing':
        locationViewsAccumulator[locationViewsAccumulator.length - 1].bottomSpacing = locationView.data?.st_spacing_height?.value || ''
        return locationViewsAccumulator
      default:
        element.type = 'empty'
        element.data = ''
    }

    locationViewsAccumulator.push(element)
    return locationViewsAccumulator
  }, []) || []

  if (!withLocationSearchCtas) {
    return viewElements
  }

  // In case of location search we have locationCtas stored directly inside the section template so no view templates for links
  const linkOnlineAppointment : IContactElement = {
    type: 'linkOnlineAppointment',
    data: {
      ...getLinkObject(locationData.data.tt_link_online_appointment, getUrlByPageId),
      globalLabelKey: 'location_search_appointment_link_label',
      iconName: 'calendar-days',
    },
  }

  const addressString = getAddressString(locationData)
  const linkDirections : IContactElement = {
    type: 'linkRoutePlanner',
    data: {
      url: `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressString)}`,
      globalLabelKey: 'location_search_directions_link_label',
      iconName: 'location-dot',
      target: ELinkTarget.Blank,
    },
  }

  const linkWebsite : IContactElement = {
    type: 'linkWebsite',
    data: {
      ...getLinkObject(locationData.data.tt_link_website, getUrlByPageId),
      globalLabelKey: 'location_search_website_link_label',
      iconName: 'globe',
    },
  }

  const linkCertificate : IContactElement = {
    type: 'linkWhgCertificates',
    data: locationData.data.tt_whg_certificates_list?.map((listElement) => ({
      ...getLinkObject(listElement, getUrlByPageId),
      globalLabelKey: 'location_search_certificate_link_label',
    })) || [],
  }

  return [...viewElements, linkOnlineAppointment, linkDirections, linkWebsite, linkCertificate]
}
