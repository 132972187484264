var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"flex items-center gap-2 cursor-pointer",class:[
    {
      'flex-row-reverse' : _vm.reversed,
      'text-red' : _vm.state === 'error',
    },
    _vm.textSize === 'lg' ? 'text-lg' : 'text-base',
  ],attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$emit('change', _vm.value || '')}}},[_c('input',{staticClass:"cursor-pointer appearance-none m-0 text-darkgreen w-5 h-5 border rounded-[50%] translate-y-[-0.075em] grid place-content-center hover:border-gray-400 active:border-gray-400 active:bg-gray-50 transition before:content-[''] before:w-3 before:h-3 before:rounded-[50%] before:scale-0 before:transition-transform before:duration-150 checked:before:scale-100 checked:border-darkgreen checked:hover:border-darkgreen checked:active:border-darkgreen checked:active:bg-white focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 shrink-0",class:_vm.inputStyle[_vm.state],attrs:{"type":"radio","required":_vm.required},domProps:{"checked":_vm.isSelected,"value":_vm.value}}),_vm._v("\n  "+_vm._s(_vm.label)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }