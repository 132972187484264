
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IImage from '../../shared/general/interfaces/IImage'
import BasePicture from '../base/BasePicture.vue'
import IVideo from '../../shared/general/interfaces/IVideo'
import { getVideoUrl } from '../../shared/general/services/VideoUrlService'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { setOne } from '../../shared/general/services/privacy/PrivacySettingsService'

@Component({
  name: 'CarouselImage',
  components: {
    BasePicture,
    CookieLayer: () => import('../CookieLayer.vue'),
  },
})
export default class CarouselImage extends Vue {
    @Prop({ required: true }) image! : IImage

    @Prop({ default: true }) lazy! : boolean

    @Prop({ default: '' }) imageAltText! : string

    @Prop() showCookieLayer! : boolean

    @Prop() video ?: IVideo

    @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

    private breakpoints : Record<string, Record<number, string>> = {
      'stage-large': {
        1920: '96:29',
        1320: '66:29',
        900: '45:29',
        600: '30:29',
      },
      'stage-medium': {
        1920: '24:5',
        1320: '33:10',
        900: '9:4',
        600: '3:2',
      },
      carousel: {
        850: '17:6',
        600: '2:1',
      },
    }

    private acceptCookies () : void {
      if (!this.video || this.video.format === 'html5') return
      setOne(this.video.format, true)
    }

    private openVideoExternal () : void {
      if (!this.video?.id) return
      window.open(getVideoUrl(this.video.id, this.video.format, true), '_blank')
    }

    private get noVideoAcceptLabel () : string {
      return globalLabelAsString('accept_cookies')
    }

    private get externalButtonAcceptLabel () : string {
      return globalLabelAsString('open_video_external_label')
    }
}
