
import {
  Component, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { NotificationData, ObservableMap } from '../../shared/general/ObservableMap'

interface IToolComponent {
  tool : string
  id : string
  type : string
}

@Component({ name: 'TrackingContainer' })
export default class TrackingContainer extends Vue {
  @Prop({ default: () => ({}) }) tools! : Record<string, string>

  private consent : Record<string, boolean> = {}

  mounted () {
    this.consent = { ...this.$store.state.PrivacySettings.settings }
    this.setTrackingTools()
  }

  private setTrackingTools () : void {
    Object.entries(this.tools).map<IToolComponent>(([tool, id]) => {
      const component = { tool, id, type: tool[0].toUpperCase() + tool.substring(1) }

      if (tool.includes('google') || tool.includes('msAdvertising') || tool.includes('websightZoominfo')) {
        component.type = 'Google'
      }

      return component
    })
      .filter(({ tool }) => this.tools[tool] && this.consent[tool])
      .forEach(async ({ tool, id, type }) => {
        try {
          const addToTrackingTools = (await import(`./Tracking${type}`)).default
          addToTrackingTools(tool, id)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`The tracking tool ${type} is not implemented yet.`)
        }
      })
  }

  beforeMount () {
    window.TRACKING_TOOLS = window.TRACKING_TOOLS || new ObservableMap()
    window.TRACKING_TOOLS.observe(({ type, changes } : NotificationData) => {
      if (type === 'set' && changes?.key) {
        window.TRACKING_TOOLS.get(changes.key).initTracking()
      }
    })
  }

  @Watch('$store.state.PrivacySettings.settings', { deep: true })
  private onConsentChange () : void {
    this.consent = { ...this.$store.state.PrivacySettings.settings }
    this.setTrackingTools()
  }
}
