
import { Component, Prop } from 'nuxt-property-decorator'
import Vue from 'vue'
import { TIcon } from '../../shared/general/types/TIcon'
import BaseIcon from './BaseIcon.vue'

@Component({
  name: 'BaseIconText',
  components: { BaseIcon },
})
export default class BaseIconText extends Vue {
  @Prop({ required: true }) label! : string

  @Prop({ required: true }) iconName! : string

  @Prop({ default: 'light' }) type! : TIcon

  @Prop({ default: false }) reversed! : boolean

  @Prop({ default: false }) rotate! : boolean

  @Prop({ default: 'default' }) spacing! : 'default' | 'large'

  private spacings : Record<string, string> = {
    default: 'gap-1.5',
    large: 'gap-2.5',
  }
}
