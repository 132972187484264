
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import IImage from '../../shared/general/interfaces/IImage'
import tailwindConfig from '../../tailwind.config.js'
import IImageResolution from '../../shared/general/interfaces/IImageResolution'

type TObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

interface IPictureResource {
  media : string
  srcset : string
}

@Component({ name: 'BasePicture' })
export default class BasePicture extends Vue {
  @Prop({ required: true }) image! : IImage

  @Prop({ default: true }) lazy! : boolean

  @Prop() aspectRatio ?: string

  @Prop({ default: 'cover' }) objectFit! : TObjectFit

  @Prop({ default: true }) fullSize! : boolean

  @Prop() breakpoints ?: Record<number, string | undefined>

  @Prop() resolutions ?: Record<number | 'max', string>

  tailwindObjectFit = {
    contain: 'object-contain',
    cover: 'object-cover',
    fill: 'object-fill',
    none: 'object-none',
    'scale-down': 'object-scale-down',
  }

  private aspectRatioMapping (ratioString : string) : number {
    const [a, b] = ratioString.split(':').map((string) => +string)
    return a / b
  }

  private get cssAspectRatio () : string {
    switch (this.aspectRatio || this.getRatioOfImage(this.imageResolutionOriginal)) {
      case '3:4':
        return 'aspect-3/4'
      case '4:3':
        return 'aspect-4/3'
      case '16:9':
        return 'aspect-video'
      case '9:16':
        return 'aspect-9/16'
      case '1:1':
        return 'aspect-square'
      default: {
        const ratio = this.aspectRatioMapping(this.aspectRatio || this.getRatioOfImage(this.imageResolutionOriginal))

        if (ratio > 1.5) return 'aspect-video'
        if (ratio > 1) return 'aspect-4/3'
        if (ratio > 0.75) return 'aspect-3/4'
        if (ratio > 0.5) return 'aspect-9/16'

        return 'aspect-square'
      }
    }
  }

  private get imageResolutions () : IImageResolution[] {
    // resolutions without original
    const [, ...resolutions] = this.image.resolutions
    return resolutions
  }

  private get imageResolutionOriginal () : IImageResolution {
    return this.image.resolutions[0]
  }

  private get imageOriginal () : IPictureResource {
    return { media: '(min-width: 0px)', srcset: this.imageResolutionOriginal.url }
  }

  private get targetAspectRatio () : string {
    return this.aspectRatio || this.getRatioOfImage(this.imageResolutionOriginal)
  }

  private get pictureResources () : IPictureResource[] {
    if (!this.image.resolutions?.length) return []
    if (this.image.resolutions?.length === 1) return [this.imageOriginal]

    if (Object.keys(this.resolutions || {})?.length) {
      return this.pictureResourcesByResolutions()
    }

    if (Object.keys(this.breakpoints || {})?.length) {
      return this.pictureResourcesWithMultipleRatios()
    }

    // tailwind viewPort values, sorted ascending
    const tailwindSizes = Object.values({ ...tailwindConfig.theme.screens })
      .map((value) => +value.replace('px', '')).sort((a, b) => a - b)

    // find resolutions that match targetAspectRatio and sort by width ascending (the smallest width first)
    const resolutionsByTargetRatio = this.imageResolutions
      .filter((res) => this.getRatioOfImage(res) === this.targetAspectRatio)
      .sort((a, b) => a.width - b.width)

    return tailwindSizes
      .map((value, index) => {
        // use next tailwind size
        // if we reached the end use the smallest resolution above or equal the tailwind size
        // if that is not possible use the current tailwind size (which was used in the previous loop)
        const maxWidth = tailwindSizes[index + 1]
          ?? resolutionsByTargetRatio.filter((res) => res.width >= tailwindSizes[index])[0]?.width
          ?? tailwindSizes[index]

        return this.mapToPictureResource(value, maxWidth)
      }).reverse()
  }

  private pictureResourcesByResolutions () : IPictureResource[] {
    let previousBreakpoint = '0'
    return Object.keys(this.resolutions || {}).sort((a, b) => (b === 'max' ? -1 : +a - +b)).reduce((result, breakpoint) => {
      const resolution : string | undefined = this.resolutions?.[breakpoint]
      const [width, height] = resolution?.includes('x') ? resolution.split('x').map((string) => +string) : [-1, -1]
      const srcset = this.imageResolutions.find((res) => res.width === width && res.height === height)?.url || this.imageResolutionOriginal.url

      // add one more element for the big screens
      // otherwise the lowest res would be used
      if (breakpoint === 'max') {
        result.push({
          media: `(min-width: ${(previousBreakpoint)}px)`,
          srcset,
        })
        return result
      }

      result.push({
        media: `(max-width: ${(+breakpoint - 1)}px)`,
        srcset,
      })

      previousBreakpoint = breakpoint
      return result
    }, [] as IPictureResource[])
  }

  private pictureResourcesWithMultipleRatios () : IPictureResource[] {
    return Object.keys(this.breakpoints || {}).reduce((result, breakpoint, index) => {
      const srcset = this.selectResolutionForViewPort(+breakpoint, this.breakpoints?.[breakpoint]
        || this.targetAspectRatio)

      result.push({
        media: `(max-width: ${(+breakpoint - 1)}px)`,
        srcset,
      })

      // add one more element for the big screens
      // otherwise the lowest res would be used
      if (index === Object.keys(this.breakpoints || {}).length - 1) {
        result.push({
          media: `(min-width: ${(breakpoint)}px)`,
          srcset,
        })
      }

      return result
    }, [] as IPictureResource[])
  }

  private getRatioOfImage (image ?: IImageResolution) : string {
    const fallback = '9:16'
    if (!image) return fallback
    const [width, height] = [image.width, image.height]
    const ratio = width / height
    if (ratio > 4) return '24:5'
    if (ratio > 3) return '96:29'
    if (ratio > 2.5) return '14:5'
    if (ratio > 1.5) return '16:9'
    if (ratio > 1) return '4:3'
    if (ratio > 0.75) return '3:4'
    return fallback
  }

  private roundToTwoDecimalPlaces (num : number) : number {
    return Math.round(num * 100) / 100
  }

  private roundToOneDecimalPlace (num : number) : number {
    return Math.round(num * 10) / 10
  }

  private aspectRatioOf (width : number, height : number) : number {
    return this.roundToOneDecimalPlace(this.roundToTwoDecimalPlaces(width) / this.roundToTwoDecimalPlaces(height))
  }

  private mapToPictureResource (value : number, maxWidth : number) : IPictureResource {
    return {
      media: `(min-width: ${value}px)`,
      srcset: this.selectResolutionForViewPort(maxWidth),
    }
  }

  private closestMatch (numbers : number[], target : number) : number {
    return numbers.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev))
  }

  private selectResolutionForViewPort (targetWidth : number, aspectRatio ?: string | undefined) : string {
    const targetAspectRatio = aspectRatio || this.targetAspectRatio

    // filter resolutions which are larger than the targetWidth and which match with the targetAspectRatio
    const resolutionsByWidthAndRatio = this.imageResolutions
      .filter((res) => res.width >= targetWidth)
      .filter((res) => res.height >= (targetWidth / this.aspectRatioMapping(targetAspectRatio)))

    // collect aspectRatios, they match with the targetAspectRatio but are not exactly equal (they are in that range)
    const aspectRatios = resolutionsByWidthAndRatio.map((res) => this.aspectRatioOf(res.width, res.height))

    // find the resolution(s) that comes closest to the target aspect ratio
    const resolutionsByClosestMatch = resolutionsByWidthAndRatio.filter((res) => {
      const resAspectRatio = this.aspectRatioOf(res.width, res.height)
      return resAspectRatio === this.closestMatch(aspectRatios, this.aspectRatioMapping(targetAspectRatio))
    })

    // we simply want the resolution closest to the targetWidth (the smallest one)
    const result = resolutionsByClosestMatch.length > 1
      ? resolutionsByClosestMatch.sort((a, b) => a.width - b.width)[0]?.url
      : resolutionsByClosestMatch[0]?.url

    // if we can't find any match we use the original image to prevent showing no image
    return result || this.imageResolutionOriginal.url
  }

  @Emit('click')
  private click () : boolean {
    return true
  }
}
