import BaseIconText from '../../../../components/base/BaseIconText.vue'
import { ILink } from '../../interfaces/ILink'
import IBaseIconText from '../../../IBaseIconText'
import ELinkTarget from '../../enums/ELinkTarget'

export interface ILocationContactData {
  component : {
    type : string
    data : ILink | IBaseIconText
  }
}

export const contactOf = (type : string, iconName : string, label : string, value ?: string) : ILocationContactData => ({
  component: {
    type,
    data: type === BaseIconText.name ? {
      // IBaseIconText
      iconName, label, reversed: true,
    } : {
      // ILink
      iconName, label, url: value, target: ELinkTarget.Self, reversed: true,
    },
  },
})

export const addLinkPrefix = (type : string, url : string) => (
  {
    mail: `mailto:${url}`,
    phone: `tel:${url}`,
  }[type] || url
)
