var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.inlineLayout
      ? 'max-w-[1320px] w-1/2 flex justify-end gap-4 absolute pointer-events-none' +
        'sm:row-span-full sm:col-span-full justify-self-center z-10 bottom-0 p-3'
      : 'gap-4 pointer-events-none'
  ]},[_c('div',{staticClass:"flex gap-4 !pt-0 w-full ml-auto",class:[
      { wrapperSpacings: _vm.inlineLayout },
      { 'md:w-[290px]': _vm.variant === 'carousel' && _vm.inlineLayout }
    ]},[_c('div',{staticClass:"flex justify-center items-center gap-x-1 grow self-center pointer-events-auto",class:{ 'md:mr-8': !_vm.inlineLayout }},_vm._l((_vm.length),function(index){return _c('div',{key:`carousel-control-indicator-${index}`,staticClass:"w-3 h-1.5 cursor-pointer focus-visible:ring focus-visible:ring-black outline-none box-content bg-clip-padding grow rounded-full transition duration-300",class:_vm.inlineLayout ? _vm.indicatorClassInlineLayout(index) : _vm.indicatorClass(index),attrs:{"data-key":index,"tabindex":"0"},on:{"click":function($event){return _vm.setIndex(index - 1)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setIndex(index -1)}}})}),0),_vm._v(" "),_c('BaseIconButton',{staticClass:"pointer-events-auto shrink-0",class:{ 'hidden md:flex border-2': !_vm.inlineLayout },attrs:{"aria-global-label-key":"prev_label","inverted":_vm.inlineLayout,"icon":"chevron-left","variant":"outline","size":_vm.variant === 'carousel' ? 'small' : 'large'},on:{"click":function($event){return _vm.slide('prev')}}}),_vm._v(" "),_c('BaseIconButton',{staticClass:"pointer-events-auto shrink-0",class:{ 'hidden md:flex border-2': !_vm.inlineLayout },attrs:{"aria-global-label-key":"next_label","inverted":_vm.inlineLayout,"icon":"chevron-right","variant":"outline","size":_vm.variant === 'carousel' ? 'small' : 'large'},on:{"click":function($event){return _vm.slide('next')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }