
import Vue from 'vue'
import {
  Component, Emit, Prop,
} from 'nuxt-property-decorator'
import TopBar from './TopBar.vue'
import ContactElements from '../contact/ContactElements.vue'
import { TAddress } from '../../shared/general/types/Map'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { TLocationCta } from '../../shared/general/types/TLocationCta'

@Component({
  name: 'LocationSidebarIntro',
  components: {
    ContactElements,
    TopBar,
  },
})
export default class LocationSidebarIntro extends Vue {
  @Prop({ required: true }) content! : TAddress

  @Prop() locationCta ?: TLocationCta

  @Emit('back')
  private back () : boolean {
    return true
  }

  private closeSidebar () : void {
    this.$store.commit('Sidebar/set', false)
  }

  private get backToSearchLabel () : string {
    return globalLabelAsString('back_to_search_label')
  }
}
