
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import BaseIcon from '../../base/BaseIcon.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_camera_url : string
  st_video_alt_text : string
  st_video_title_text : string
  st_background : any
}

type TStreamState = 'started' | 'paused' | 'ended' | null

@Component({
  name: 'StCameraLivestream',
  components: {
    BaseIcon, BaseGridLayout,
  },
})
export default class StCameraLivestream extends FSXABaseSection<IPayload> {
  private videoPlayer ?: HTMLVideoElement

  private streamState : TStreamState = null

  private streamSrc : string = this.payload.st_camera_url ?? ''

  $refs! : {
    videoPlayer ?: HTMLVideoElement
  }

  mounted () {
    this.videoPlayer = this.$refs.videoPlayer
    this.streamSrc = this.createUrlWithCache()
  }

  private createUrlWithCache () : string {
    return this.payload?.st_camera_url ? `${this.payload.st_camera_url}?nocache=${new Date().getTime()}` : ''
  }

  private get altText () : string | undefined {
    return this.payload.st_video_alt_text
  }

  private get titleText () : string | undefined {
    return this.payload.st_video_title_text
  }

  private get background () : string | undefined {
    const backgroundMap : Record<string, string> = {
      gray: 'bg-gray-50',
      white: 'bg-white',
      green: 'bg-darkgreen',
    }

    return backgroundMap[this.payload.st_background.value] ?? ''
  }

  private get liveCamerasLabel () : string {
    return globalLabelAsString('live_cameras_label')
  }

  private play () : void {
    if (this.streamState === 'started' || this.streamState === 'ended') {
      this.reload()
      return
    }
    this.videoPlayer?.play()
    this.playStateChange()
  }

  private playStateChange () : void {
    this.streamState = 'started'
  }

  private reload () : void {
    this.streamSrc = this.createUrlWithCache()
    this.$nextTick(() => {
      this.videoPlayer?.load()
      this.videoPlayer?.play()
    })
    this.playStateChange()
  }

  private pause () : void {
    this.videoPlayer?.pause()
    this.streamState = 'paused'
  }

  private end () : void {
    this.streamState = 'ended'
  }
}
