
import { ProjectProperties } from 'fsxa-api'
import { FSXARichText } from 'fsxa-pattern-library'
import {
  Component, Emit, InjectReactive, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseLink from './base/BaseLink.vue'
import Paragraph from './fsxa/richtext/Paragraph.vue'

@Component({
  name: 'CookieLayer',
  components: {
    BaseLink,
    'rich-text': async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class CookieLayer extends Vue {
  @Prop({ default: '' }) noVideoButtonAccept! : string

  @Prop({ default: '' }) externalButtonAccept! : string

  @Prop({ required: true }) hintTextTarget! : 'video' | 'map'

  @Prop({ default: 'large' }) buttonSize! : 'small' | 'large'

  @InjectReactive({ from: 'globalSettings' }) gs! : ProjectProperties | null

  private layerIsSmall : boolean = false

  private hintTextTargets : Record<string, string> = {
    video: 'pt_no_video_hint_text',
    map: 'pt_map_blocked_hint_text',
  }

  mounted () {
    this.layerIsSmall = (this.$el as HTMLElement).offsetWidth < 600
    window.addEventListener('resize', () => {
      this.layerIsSmall = (this.$el as HTMLElement).offsetWidth < 600
    })
  }

  private get text () : FSXARichText[] | undefined {
    const key = this.hintTextTargets[this.hintTextTarget]
    return this.gs?.data?.ps_country_project_settings?.[key]?.map(
      (text : Paragraph) => text?.content,
    )
  }

  @Emit('accept-button-click')
  private acceptButton () : boolean {
    return true
  }

  @Emit('external-button-click')
  private externalButton () : boolean {
    return true
  }
}
