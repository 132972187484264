
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { IToolBarElement } from '../../../shared/general/interfaces/IToolbarElement'
import { notUndefined } from '../../../shared/general/services/TypeAssertions'

@Component({ name: 'BaseToolbarWrapper' })
export default class BaseToolbarWrapper extends Vue {
  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  @Watch('$store.state.ToolbarElements.onlineAppointmentLink')
  @Watch('$store.state.ToolbarElements.showLocationSidebarElement')
  private toolbarElementsChange () : void {
    this.$store.commit('ToolbarElements/setFixed', this.fixedToolbarElements)
  }

  private get fixedToolbarElements () : IToolBarElement[] {
    return [this.locationSidebarElement, this.onlineAppointmentSidebarElement].filter(notUndefined)
  }

  private get locationSidebarElement () : IToolBarElement | undefined {
    if (!this.$store.state.ToolbarElements.showLocationSidebarElement) return undefined
    return {
      iconName: 'location-dot',
      text: globalLabelAsString('toolbar_location_sidebar_label'),
      isSidebar: true,
      sidebarComponent: 'LocationSearch',
      isBookmarkable: true,
    }
  }

  private get onlineAppointmentSidebarElement () : IToolBarElement | undefined {
    if (!this.$store.state.ToolbarElements.onlineAppointmentLink) return undefined
    return {
      iconName: 'calendar-days',
      text: globalLabelAsString('online_appointment_label'),
      href: this.$store.state.ToolbarElements.onlineAppointmentLink,
    }
  }
}
