
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Section } from 'fsxa-api'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

interface ILocationSpecificContents {
  data ?: {
    st_locations_remote ?: TRemoteDatasetIndex
    st_location_elements ?: Section[]
  }
}

interface ILocationTypeSpecificContents {
  data ?: {
    st_location_types_remote ?: TRemoteDatasetIndex
    st_location_type_elements ?: Section[]
  }
}

interface IPayload {
  st_location_default_contents ?: Section[]
  st_location_specific_contents ?: ILocationSpecificContents[]
  st_location_type_specific_contents ?: ILocationTypeSpecificContents[]
}

@Component({
  name: 'StLocationContent',
  components: { LocationContent: () => import('../../LocationContent.vue') },
})
export default class StLocationContent extends FSXABaseSection<IPayload> {
  private locationId : string = ''

  private remoteDatasetLoaded = false

  async mounted () {
    await this.watchForLocationChanges()
    await this.fetchRemoteDatasets()
    this.remoteDatasetLoaded = true
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  private async watchForLocationChanges () : Promise<void> {
    // for some reason using @Watch will break the rendering of other sections, so do not change this!
    // wrapping this in a 'async' function with 'await' is required otherwise it won't trigger when object changes
    this.$watch('$store.state.Locations.savedLocations', this.setLocationId, { deep: true, immediate: true })
  }

  private async setLocationId () : Promise<void> {
    this.locationId = await this.$store.dispatch('Locations/getSavedLocationId', this.$store.state.Page.locationType)
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      ...this.locationSpecificLocationRemoteDatasetIndexes.map((index) => getOrFetchRemoteDatasets(index)),
      ...this.locationTypeSpecificRemoteDatasetIndexes.map((index) => getOrFetchRemoteDatasets(index)),
    ])
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get hasLocationContent () : boolean {
    return !!this.filteredLocationSpecificContents.length || !!this.filteredLocationTypeSpecificContents.length
      || !!this.locationDefaultContents.length
  }

  private get locationDefaultContents () : Section[] {
    return this.payload.st_location_default_contents || []
  }

  private get locationSpecificContents () : ILocationSpecificContents[] {
    return this.payload.st_location_specific_contents || []
  }

  private get locationSpecificLocationRemoteDatasetIndexes () : TRemoteDatasetIndex[] {
    return this.locationSpecificContents.flatMap((item) => item.data?.st_locations_remote || []) || []
  }

  private get filteredLocationSpecificContents () : Section[] {
    return this.locationSpecificContents
      .filter((item) => getRemoteDatasetsFromStore(item.data?.st_locations_remote).some((location) => location.id === this.locationId))
      .flatMap((item) => item.data?.st_location_elements || []) || []
  }

  private get locationTypeSpecificContents () : ILocationTypeSpecificContents[] {
    return this.payload.st_location_type_specific_contents || []
  }

  private get locationTypeSpecificRemoteDatasetIndexes () : TRemoteDatasetIndex[] {
    return this.locationTypeSpecificContents.flatMap((item) => item.data?.st_location_types_remote || []) || []
  }

  private get filteredLocationTypeSpecificContents () : Section[] {
    const savedLocationTypes : string[] = Object.keys(this.$store.state.Locations.savedLocations || {})
    if (!this.$store.state.Page.useLocationReference || !savedLocationTypes.length) return []

    return this.locationTypeSpecificContents
      .filter((item) => getRemoteDatasetsFromStore(item.data?.st_location_types_remote)
        .some((locationType) => !!locationType.data?.tt_key && savedLocationTypes.includes(locationType.data.tt_key)))
      .flatMap((item) => item.data?.st_location_type_elements || []) || []
  }
}
