
import { Component, Prop, Vue } from 'nuxt-property-decorator'

type TInputState = 'default' | 'error'

@Component({
  name: 'BaseRadio',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
})
export default class BaseRadio extends Vue {
  @Prop({ required: true }) label! : string

  @Prop({ default: '' }) modelValue! : string

  @Prop({ default: undefined }) value! : string | undefined

  @Prop({ default: false }) required! : boolean

  @Prop({ default: false }) reversed! : boolean

  @Prop({ default: 'default' }) state! : TInputState

  @Prop({ default: 'lg' }) textSize! : 'base' | 'lg'

  private inputStyle = {
    default: 'border-gray-300 focus-visible:outline-black',
    error: 'text-red border-red outline outline-4 outline-red-400',
  }

  private get isSelected () : boolean {
    return this.modelValue === this.value
  }
}
