
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import BaseIconButton from '../base/BaseIconButton.vue'
import { TVariant } from '../../shared/general/types/TCarousel'

@Component({
  name: 'CarouselControls',
  components: { BaseIconButton },
})
export default class CarouselControls extends Vue {
  @Prop({ required: true }) variant! : TVariant

  @Prop({ required: true }) length! : number

  @Prop({ required: true }) currentIndex! : number

  @Prop({ default: false }) inlineLayout! : boolean

  @Prop() wrapperSpacings ?: string

  private setIndex (index : number) : void {
    if (this.currentIndex === index) return
    this.$emit('slide', index)
  }

  private slide (direction : 'prev' | 'next') : void {
    let newIndex = direction === 'prev' ? this.currentIndex - 1 : this.currentIndex + 1
    if (newIndex === this.length) newIndex = 0
    if (newIndex < 0) newIndex = this.length - 1
    this.setIndex(newIndex)
  }

  private indicatorClass (index : number) : string {
    return this.currentIndex === index - 1
      ? 'bg-white hover:bg-white md:bg-darkgreen md:hover:bg-darkgreen'
      : 'bg-white/50 hover:bg-white/75 md:bg-black/50 md:hover:bg-black/75'
  }

  private indicatorClassInlineLayout (index : number) : string {
    return this.currentIndex === index - 1
      ? 'bg-white hover:bg-white'
      : 'bg-white/50 hover:bg-white/75'
  }
}
