
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import IContactElement from '../../shared/general/interfaces/IContactElement'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { TLocationCta } from '../../shared/general/types/TLocationCta'

@Component({
  name: 'LocationCard',
  components: {
    BaseButton: () => import('../base/BaseButton.vue'),
    ContactElements: () => import('../contact/ContactElements.vue'),
  },
})
export default class LocationCard extends Vue {
  @Prop({ required: true }) location! : IContactElement[]

  @Prop({ required: true }) id! : string

  @Prop({ default: false }) isActive! : boolean

  @Prop({ default: false }) showDetailsButton! : boolean

  @Prop() locationCta ?: TLocationCta

  private get buttonVariant () : EButtonVariant {
    return this.isActive ? EButtonVariant.Default : EButtonVariant.Outline
  }

  private get detailsLabel () : string {
    return globalLabelAsString('location_search_details')
  }

  @Emit('card-click')
  private cardClick () : string {
    return this.id
  }

  @Emit('details-click')
  private detailsClick () : string {
    return this.id
  }
}
