
import { File, Image, Option } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component, Provide } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IImage from '../../../shared/general/interfaces/IImage'
import { mapImage } from '../../../shared/general/services/ImageService'
import IVideo from '../../../shared/general/interfaces/IVideo'
import { buildPlayerElements, getVideoFormat } from '../../../shared/general/services/VideoUrlService'
import IImageVideoContent from '../../../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../../../shared/general/services/LightboxService'

interface IPayload {
  st_alt_text ?: string
  st_title_text ?: string
  st_image_video_caption ?: string
  st_video_format ?: Option
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
  st_video_design ?: Option
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_image ?: Image
  st_image_design ?: Option
  st_image_decorative ?: boolean
  st_toggle_image_video ?: boolean
  st_zoom_image_active ?: boolean
  st_zoom_image ?: Image
  st_zoom_image_alt_text ?: string
}

type TImageVideoDesign = 'standard' | 'large' | 'full-width'

@Component({
  name: 'StImageVideo',
  components: {
    BaseGridLayout,
    BaseImageVideo: () => import('../../ImageVideo/BaseImageVideo.vue'),
  },
})
export default class StImageVideo extends FSXABaseSection<IPayload> {
  @Provide('ignoreGridSizeUseWidthFull') ignoreGridSizeUseWidthFull : boolean = (this.imageVideoDesign === 'full-width')

  private fallbackTo<T> (fallback : T, val ?: T | null) : T {
    return val ?? fallback
  }

  private get imageVideoDesign () : TImageVideoDesign {
    const toggleImage = this.fallbackTo(true, this.payload.st_toggle_image_video)
    if (toggleImage) return this.fallbackTo('standard', this.payload.st_image_design?.key as TImageVideoDesign | undefined)
    return this.fallbackTo('standard', this.payload.st_video_design?.key as TImageVideoDesign | undefined)
  }

  private get caption () : string {
    return this.payload.st_image_video_caption ?? ''
  }

  private get lightbox () : boolean {
    const toggleImage = this.fallbackTo(true, this.payload.st_toggle_image_video)
    const showZoomImage = this.fallbackTo(false, this.payload.st_zoom_image_active)
    return toggleImage ? showZoomImage : true
  }

  private get imageOrVideo () : 'image' | 'video' {
    return this.payload.st_toggle_image_video ? 'image' : 'video'
  }

  private get video () : IVideo {
    return {
      id: this.payload.st_video_id ?? '',
      format: getVideoFormat(this.payload.st_video_format?.key),
      htmlPlayerElements: buildPlayerElements({
        mp4: this.payload.st_mp4,
        webm: this.payload.st_webm,
        ogv: this.payload.st_ogv,
      }),
      privacyHash: this.payload.st_vimeo_privacy_hash,
    }
  }

  private get lightboxImage () : IImage | undefined {
    const image = mapImage(this.payload.st_zoom_image, this.payload.st_zoom_image_alt_text, this.payload.st_zoom_image_alt_text)
    return image.resolutions.length ? image : undefined
  }

  private get image () : IImage {
    return mapImage(this.payload.st_image, this.payload.st_alt_text, this.payload.st_title_text, this.payload.st_image_decorative)
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private showLightbox (content : IImageVideoContent) : void {
    if (!this.lightbox && this.imageOrVideo !== 'video') return
    openLightbox(content)
  }
}
