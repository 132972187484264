
import Vue from 'vue'
import {
  Component, InjectReactive, Prop, Watch,
} from 'nuxt-property-decorator'
import { Dataset, ProjectProperties } from 'fsxa-api'
import LocationSearchMap from './LocationSearchMap.vue'
import LocationSidebarIntro from './LocationSidebarIntro.vue'
import { TLocationSearchView } from '../../shared/fsxa/types/TView'
import {
  TAddress, TGeoCoderResult, TLatLngBoundsLiteral, TLocation,
} from '../../shared/general/types/Map'
import BaseInput from '../base/form/BaseInput.vue'
import BaseButton from '../base/BaseButton.vue'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import {
  createGeocoderResultMock,
  DEVICE_LOCATION_KEY,
  mapAddresses, mapMarkers, requestDeviceLocation, searchByInput, searchLocations, setupAutocomplete,
} from '../../shared/fsxa/services/LocationService'
import { TPreFilter } from '../../shared/general/types/TPreFilter'
import IDropdownOption from '../../shared/general/interfaces/IDropdownOption'
import IFilterElement from '../../shared/general/interfaces/IFilterElement'
import LocationPreFilters from './LocationPreFilters.vue'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import { ILocationTypeFilterData } from '../../shared/fsxa/interfaces/ILocationData'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../shared/fsxa/services/RemoteDatasetService'
import BasePill from '../base/BasePill.vue'
import BaseHeadline from '../base/BaseHeadline.vue'
import { TLocationCta } from '../../shared/general/types/TLocationCta'
import fsxaProxyApiRemote from '../../shared/fsxa/services/FsxaProxyApiRemote'
import createContactArray from '../../shared/fsxa/services/ContactView'
import { nuxt } from '../../shared/general/logger/LogKey'
import { Logger } from '../../shared/general/logger/Logger'
import DeviceLocationLink from './DeviceLocationLink.vue'

@Component({
  name: 'LocationSearch',
  components: {
    DeviceLocationLink,
    BaseHeadline,
    BasePill,
    BaseGridLayout,
    LocationPreFilters,
    BaseInput,
    BaseButton,
    LocationSearchMap,
    LocationSidebarIntro,
  },
})
export default class LocationSearch extends Vue {
  @InjectReactive({ from: 'globalSettings' }) globalSettings! : ProjectProperties | null

  @InjectReactive({ from: 'getUrlByPageId' }) getUrlByPageId! : Function

  @Prop() view ?: TLocationSearchView

  @Prop({ default: false }) showLocationsWithoutType! : boolean

  @Prop({ required: true }) locationTypes! : Dataset[]

  @Prop({ required: true }) preFilters! : ILocationTypeFilterData[]

  @Prop({ required: true }) tagFilters! : string[]

  @Prop() locationTypeForCommercialVehicles ?: string

  @Prop() preSelectedDropdownOption ?: IDropdownOption

  @Prop({ default: false }) inSidebar! : boolean

  @Prop() locationCta ?: TLocationCta

  $refs! : {
    section : HTMLElement
    searchInput : BaseInput
  }

  private showIntroCard : boolean = false

  private locationIntroContent : TAddress | null = null

  private selectedDropdownOption : IDropdownOption = { id: '', label: '', value: '' }

  private selectedFilterOptions : IFilterElement[] = []

  private commercialVehicle : boolean = false

  private gettingDeviceLocation = false

  private smallView = false

  private initialized = false

  private showMap = false

  private geocoder ?: google.maps.Geocoder

  private searchedLocation ?: TGeoCoderResult

  private searchTerm : string = ''

  private geocoderError = false

  private deviceLocationError = false

  private resizeObserver : ResizeObserver | null = null

  async mounted () {
    await getOrFetchRemoteDatasets(this.countryRemoteDatasetIndex)

    // setup resize observer
    this.resizeObserver = new ResizeObserver((changes) => {
      this.smallView = changes[0].contentRect.width < 600
    })

    if (this.$refs.section) this.resizeObserver.observe(this.$refs.section)

    // Initialize GoogleMaps
    await this.$store.dispatch('GoogleMaps/init', {
      apiKey: this.globalSettings?.data.ps_google_maps_api_key,
      version: 'weekly',
      libraries: ['places'],
    })

    const { google: googleMaps } = this.$store.state.GoogleMaps
    this.geocoder = new googleMaps.maps.Geocoder()

    this.searchFromUrlParams()
    // Init autocomplete and search from URL params
    this.$nextTick(() => {
      this.initAutocomplete()
    })
  }

  unmounted () {
    this.resizeObserver?.disconnect()
  }

  private async loadMarkers (bounds : TLatLngBoundsLiteral, initialSearch : boolean) : Promise<[TLocation[], TAddress[]]> {
    const { locations, isNextLocation } = await searchLocations({
      locale: this.$store.state.Locale.fsxaLocale,
      searchedLocation: this.searchedLocation!,
      initialSearch,
      showLocationsWithoutType: this.showLocationsWithoutType,
      boundaries: bounds,
      preFilters: this.preFilters,
      tagFilters: this.tagFilters,
      locationType: this.selectedDropdownOption.id ? this.selectedDropdownOption : undefined,
      commercialVehicles: this.commercialVehicle,
    })

    const markers = mapMarkers(locations, isNextLocation)
    const addresses = mapAddresses({
      locations,
      view: this.view,
      getUrlByPageId: this.getUrlByPageId,
    })

    return [markers, addresses]
  }

  private async search (searchTerm ?: string) : Promise<void> {
    if (searchTerm === this.deviceLocationLabel) {
      await this.getFromDeviceLocation()
      return
    }

    this.deviceLocationError = false

    const [success, searchedLocation] = await searchByInput(searchTerm, this.geocoder)

    if (!success) {
      this.geocoderError = true
      return
    }

    this.geocoderError = false
    this.searchTerm = searchTerm ?? ''

    // ! is necessary, as TypeScript is not intelligent enough to realize that it will be filled if success === true
    this.searchedLocation = searchedLocation!

    this.applySearchParameters()
    this.openMap()
  }

  private async getSavedLocation () : Promise<string> {
    return this.$store.dispatch('Locations/getSavedLocationId', this.$store.state.ToolbarElements.locationSidebarType?.value)
  }

  @Watch('$store.state.Sidebar.open', { immediate: true })
  private async sidebarOpenStateChange () : Promise<void> {
    try {
      const locationId = await this.getSavedLocation()
      if (!locationId) return

      const locationData = await fsxaProxyApiRemote.fetchElement({
        id: locationId,
        locale: this.$store.state.Locale.fsxaLocale,
      })

      // If no location was found, there was none bookmarked, so we don't show the card in the sidebar
      if (!locationData) return

      const location = createContactArray({
        locationData,
        locationViews: this.view?.data?.tt_config_result || [],
        getUrlByPageId: this.getUrlByPageId,
        withLocationSearchCtas: true,
      })

      const detail = createContactArray({
        locationData,
        locationViews: this.view?.data?.tt_config_detail || [],
        getUrlByPageId: this.getUrlByPageId,
        withLocationSearchCtas: true,
      })

      this.locationIntroContent = {
        id: locationId,
        distance: undefined,
        detail,
        location,
      } as TAddress

      this.showIntroCard = !!locationId
    } catch (e) {
      Logger.warn(nuxt, 'Could not get saved location', e)
    }
  }

  private async searchFromUrlParams () {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('locationtype')) {
      this.selectedDropdownOption = this.filterOptions[0]?.options
        ?.find((option) => option.label === urlParams.get('locationtype'))
        ?? { id: '', label: '', value: '' }
    }

    this.commercialVehicle = urlParams.has('commercialvehicles')

    // Do not trigger a search if there is not searchfield term given
    if (!urlParams.has('searchfield')) return

    const isDeviceLocation = urlParams.get('searchfield') === DEVICE_LOCATION_KEY

    this.searchTerm = isDeviceLocation ? this.deviceLocationLabel : urlParams.get('searchfield')!

    // If we have device location request it and do the things (request, mock searchedLocation, open map)
    if (isDeviceLocation) {
      await this.getFromDeviceLocation()
      return
    }

    // Otherwise use address data with GeoCoder for lat/lng
    await this.search(this.searchTerm)
  }

  private async getFromDeviceLocation () : Promise<void> {
    this.gettingDeviceLocation = true
    try {
      const position = await requestDeviceLocation()
      const { latitude, longitude } = position.coords
      this.deviceLocationRetrieved(createGeocoderResultMock(latitude, longitude))
    } catch (e) {
      this.deviceLocationError = true
      this.gettingDeviceLocation = false
      Logger.warn(nuxt, 'Could not get device location', e)
    }
  }

  private deviceLocationRetrieved (deviceLocation : TGeoCoderResult | null) {
    if (!deviceLocation) {
      this.deviceLocationError = true
      return
    }

    this.searchedLocation = deviceLocation
    this.searchTerm = this.deviceLocationLabel
    this.applySearchParameters(DEVICE_LOCATION_KEY)
    this.openMap()
    this.gettingDeviceLocation = false
  }

  private applySearchParameters (alternateSearchField ?: string) {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('searchfield', alternateSearchField ?? this.searchTerm)

    const path = urlParams.toString() ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname
    window.history.replaceState({}, '', path)
  }

  private openMap () {
    // We split up initialization and visibility to only load Google Maps APIs when a search was actually done
    // and then load the map completely
    // Afterward we just want to hide and show without new init to save GeoCoder and DynamicMaps API cost
    this.initialized = true
    this.showMap = true
  }

  private initAutocomplete () : void {
    const searchElement = this.$refs.searchInput?.$el.querySelector('input')
    if (!searchElement) return
    setupAutocomplete(searchElement, this.countryCode ?? '', this.search)
  }

  private get dropDownOptions () : IDropdownOption[] {
    return this.locationTypes.map((type : Dataset) => ({
      id: type.id,
      label: type.data?.tt_name,
      value: type.data?.tt_key,
    }))
  }

  private get filterOptions () : IFilterElement[] {
    return [{ listTitle: this.typeOfLocationLabel, options: this.dropDownOptions, multiSelect: false }]
  }

  private get selectedFilter () : TPreFilter {
    return {
      filterValue: this.selectedDropdownOption.value || '',
      commercialVehicles: this.showCommercialVehicleFilter && this.commercialVehicle,
    }
  }

  private updateFilters (selected ?: TPreFilter) {
    this.selectedFilterOptions = this.filterOptions.map((filterOption) => ({
      ...filterOption,
      options: filterOption.options?.filter((dropdownOption) => dropdownOption.value === selected?.filterValue),
    }))

    this.selectedDropdownOption = this.selectedFilterOptions?.[0]?.options?.[0] || { id: '', label: '', value: '' }
    this.commercialVehicle = selected?.commercialVehicles ?? false

    const urlParams = new URLSearchParams(window.location.search)
    if (this.selectedDropdownOption?.label) {
      urlParams.set('locationtype', this.selectedDropdownOption.label)
      if (this.commercialVehicle) {
        urlParams.set('commercialvehicles', 'true')
      } else {
        urlParams.delete('commercialvehicles')
      }
    } else {
      urlParams.delete('locationtype')
      urlParams.delete('commercialvehicles')
    }

    const path = urlParams.toString() ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname
    window.history.replaceState({}, '', path)
  }

  private get searchError () : string {
    if (this.geocoderError) {
      return this.enterSearchTermLabel
    }

    if (this.deviceLocationError) {
      return this.deviceLocationErrorLabel
    }

    return ''
  }

  private get showCommercialVehicleFilter () : boolean {
    if (!this.locationTypeForCommercialVehicles) return false

    if (this.selectedDropdownOption?.value) {
      return this.locationTypeForCommercialVehicles === this.selectedDropdownOption.value
    }

    if (this.preSelectedDropdownOption?.value) {
      return this.locationTypeForCommercialVehicles === this.preSelectedDropdownOption.value
    }

    return false
  }

  private get postcodeCityLabel () : string {
    return globalLabelAsString('postcode_city_label')
  }

  private get enterSearchTermLabel () : string {
    return globalLabelAsString('enter_search_term_label')
  }

  private get searchButtonLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get typeOfLocationLabel () : string {
    return globalLabelAsString('type_of_location_label')
  }

  private get headline () : string {
    return globalLabelAsString('search_location_label')
  }

  private get suitableForCommercialVehiclesLabel () : string {
    return globalLabelAsString('suitable_for_commercial_vehicles_label')
  }

  private get deviceLocationLabel () : string {
    return globalLabelAsString('device_location_label')
  }

  private get deviceLocationErrorLabel () : string {
    return globalLabelAsString('device_location_error_label')
  }

  private get countryRemoteDatasetIndex () {
    return this.globalSettings?.data?.ps_country_remote
  }

  private get countryCode () : string | undefined {
    return getRemoteDatasetsFromStore(this.countryRemoteDatasetIndex)[0]?.data?.tt_code?.toLowerCase()
  }

  @Watch('showMap')
  private onShowMapChanged () {
    if (this.showMap) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }
}
