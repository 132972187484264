
import Vue from 'vue'
import {
  Component, Prop, Emit, Watch,
} from 'nuxt-property-decorator'
import BaseIconText from '../base/BaseIconText.vue'
import { createGeocoderResultMock, requestDeviceLocation } from '../../shared/fsxa/services/LocationService'
import { TGeoCoderResult } from '../../shared/general/types/Map'
import { nuxt } from '../../shared/general/logger/LogKey'
import { Logger } from '../../shared/general/logger/Logger'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  components: {
    BaseIconText,
  },
})
export default class DeviceLocationLink extends Vue {
  @Prop({ default: false }) isRetrieving! : boolean

  @Prop({ default: false }) onlyClick! : boolean

  private gettingDeviceLocation = false

  mounted () {
    this.gettingDeviceLocation = this.isRetrieving
  }

  private async getDeviceLocation () {
    this.gettingDeviceLocation = true
    try {
      const position = await requestDeviceLocation()
      this.gettingDeviceLocation = false
      const { latitude, longitude } = position.coords
      const searchedLocation = createGeocoderResultMock(latitude, longitude)

      this.deviceLocationRetrieved(searchedLocation)
    } catch (err) {
      this.gettingDeviceLocation = false
      this.deviceLocationRetrieved(null)
      Logger.warn(nuxt, 'Could not retrieve device location', err)
    }
  }

  private get requestDeviceLocationLabel () : string {
    return globalLabelAsString('request_device_location_label')
  }

  private get retrievingDeviceLocationLabel () : string {
    return globalLabelAsString('retrieving_device_location_label')
  }

  private get deviceLocationLabel () : string {
    return this.gettingDeviceLocation ? this.retrievingDeviceLocationLabel : this.requestDeviceLocationLabel
  }

  private click () : void {
    this.clicked()
    if (this.onlyClick) return

    this.getDeviceLocation()
  }

  @Emit('click')
  private clicked () : boolean {
    return true
  }

  @Emit('device-location-retrieved')
  private deviceLocationRetrieved (location : TGeoCoderResult | null) : TGeoCoderResult | null {
    return location
  }

  @Watch('isRetrieving')
  private onIsRetrievingChanged () {
    this.gettingDeviceLocation = this.isRetrieving
  }
}
