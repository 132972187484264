
import Vue from 'vue'
import {
  Component,
  Emit,
  Prop,
  Watch,
} from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { TPreFilter } from '../../shared/general/types/TPreFilter'
import BaseCheckbox from '../base/form/BaseCheckbox.vue'
import BaseRadio from '../base/form/BaseRadio.vue'
import ICheckbox from '../../shared/general/interfaces/ICheckbox'
import IDropdownOption from '../../shared/general/interfaces/IDropdownOption'

@Component({
  components: {
    BaseRadio,
    BaseCheckbox,
  },
})
export default class LocationTypeFilters extends Vue {
  @Prop({ default: () => [] }) filters! : IDropdownOption[]

  @Prop({ required: false }) commercialVehiclesFilter ?: string

  @Prop({ default: () => ({ filterValue: '', commercialVehicles: false }) }) preSelection! : TPreFilter

  private currentSelection : TPreFilter = { filterValue: '', commercialVehicles: false }

  private get typeOfLocationLabel () : string {
    return globalLabelAsString('type_of_location_label')
  }

  private get suitableForCommercialVehiclesLabel () : string {
    return globalLabelAsString('suitable_for_commercial_vehicles_label')
  }

  private radioChanged (filterValue : string) {
    const commercialVehicles = this.currentSelection.commercialVehicles && filterValue === this.commercialVehiclesFilter
    this.currentSelection = { filterValue, commercialVehicles }
    this.fireChange()
  }

  private checkboxClicked (state : ICheckbox) {
    this.currentSelection = { filterValue: this.commercialVehiclesFilter || '', commercialVehicles: state.value }
    this.fireChange()
  }

  @Watch('preSelection', { immediate: true })
  private handlePreSelectionChanged () {
    if (this.currentSelection.filterValue !== this.preSelection.filterValue
      || this.currentSelection.commercialVehicles !== this.preSelection.commercialVehicles
    ) {
      this.currentSelection = this.preSelection
    }
  }

  @Emit('change')
  private fireChange () : TPreFilter {
    return { ...this.currentSelection }
  }
}
