
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import { globalLabelAsString } from '../shared/general/services/StoreService'
import IDropdownOption from '../shared/general/interfaces/IDropdownOption'
import IFilterElement from '../shared/general/interfaces/IFilterElement'
import EButtonVariant from '../shared/general/enums/EButtonVariant'
import getClassWithQuery from '../shared/general/services/ContainerQueryService'

export type TFilterActions = 'load-selected-filters' | 'restore-previous-state'

@Component({
  name: 'Filters',
  components: {
    BaseCheckbox: () => import('./base/form/BaseCheckbox.vue'),
    BaseIcon: () => import('./base/BaseIcon.vue'),
    BaseButton: () => import('./base/BaseButton.vue'),
    BaseIconButton: () => import('./base/BaseIconButton.vue'),
    BaseTab: () => import('./base/tab/BaseTab.vue'),
    BaseTabElement: () => import('./base/tab/BaseTabElement.vue'),
  },
})
export default class Filters extends Vue {
  @Prop({ required: true }) filterLabel! : string

  @Prop({ default: [] }) options! : IFilterElement[]

  @Prop() preselectedValues ?: IFilterElement[]

  @Prop({ default: false }) showBackgroundOverlay! : boolean

  @Prop({ default: false }) contained! : boolean

  private thisFiltersVisible : boolean = false

  private selectedValues : IFilterElement[] = []

  private originalFilter : IFilterElement[] = []

  created () {
    this.fillSelectedValues()

    const unwatch = this.$watch('options', () => {
      this.fillSelectedValues()
      unwatch()
    })
  }

  private getClassWithQuery (classes : string) : string {
    return getClassWithQuery(this.contained, classes)
  }

  private fillSelectedValues () {
    this.selectedValues = this.options.map((list) => ({
      listTitle: list.listTitle,
      options: [],
      multiSelect: list.multiSelect,
    }))
  }

  private get filtersVisible () : boolean {
    return this.$store.state.ModalContent.isFilterOpen
  }

  private get filterButtonVariant () : EButtonVariant {
    return this.filtersVisible ? EButtonVariant.Default : EButtonVariant.Outline
  }

  private get showResultsLabel () : string {
    return globalLabelAsString('show_results_label')
  }

  private setFiltersVisibility (value : boolean) : void {
    this.thisFiltersVisible = value
    this.$store.commit('ModalContent/setFilterOpen', value)
  }

  private getCorrectListIndex (listTitle : string) : number {
    return this.selectedValues.findIndex((list) => list.listTitle === listTitle)
  }

  private findOptionIndex (listIndex : number, option : IDropdownOption) : number {
    return (this.selectedValues[listIndex]?.options || []).findIndex(
      (listElement) => listElement?.value === option?.value && listElement?.label === option?.label,
    )
  }

  private isSelected (listTitle : string, option : IDropdownOption) : boolean {
    const listIndex = this.getCorrectListIndex(listTitle)
    return this.findOptionIndex(listIndex, option) > -1
  }

  private selectValue (listTitle : string, newOption : IDropdownOption) : void {
    const listIndex = this.getCorrectListIndex(listTitle)
    const index = this.findOptionIndex(listIndex, newOption)

    if (index > -1) {
      this.selectedValues[listIndex]?.options?.splice(index, 1)
    } else {
      this.selectedValues[listIndex]?.options?.push(newOption)
    }
  }

  private setSingleSelectValue (listTitle : string, filter : IDropdownOption) : void {
    const listIndex = this.getCorrectListIndex(listTitle)
    if (this.selectedValues[listIndex]) this.selectedValues[listIndex].options = []

    if (!this.isSelected(listTitle, filter)) {
      this.selectedValues[listIndex]?.options?.push(filter)
    }

    this.$forceUpdate()
  }

  @Emit('process-filter-changes')
  private processFilterChanges (action : TFilterActions) : { filters : IFilterElement[], action : TFilterActions } {
    if (action === 'load-selected-filters') {
      this.originalFilter = JSON.parse(JSON.stringify(this.selectedValues))
    } else {
      this.selectedValues = JSON.parse(JSON.stringify(this.originalFilter))
    }
    return { filters: this.selectedValues, action }
  }

  @Emit('show-results')
  private showResults () : IFilterElement[] {
    this.setFiltersVisibility(false)
    return this.selectedValues
  }
}
