
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { SmartSearch } from 'smartsearch.js'
import IOption from '../../../shared/general/interfaces/IOption'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { TTargetGroup } from '../../../shared/general/types/TTargetGroup'
import {
  getSmartSearchInstance,
  searchByFacets,
  TSmartSearchFacetMapping,
  TSmartSearchMapping,
  TSmartSearchParams,
} from '../../../shared/general/services/SmartSearchService'
import { TTeaserSearchResult } from '../../../shared/general/interfaces/TTeaserSearchResult'
import { getURLParameterValue, setURLParameterValue } from '../../../shared/general/services/URLParameterService'

interface IPayload {
  st_target_group ?: IOption
  st_show_industry_filter ?: boolean
}

interface IFacetResult {
  filterIndustry : string[]
  filterServiceObject : string[]
  filterServiceDivision : string[]
}

@Component({
  name: 'StServiceOverview',
  components: {
    BaseGridLayout,
    ServiceSearch: () => import('../../overview/ServiceSearch.vue'),
    ServiceFilterPanel: () => import('../../overview/ServiceFilterPanel.vue'),
    ServiceOverview: () => import('../../overview/ServiceOverview.vue'),
  },
})
export default class StServiceOverview extends FSXABaseSection<IPayload> {
  private searchTerm : string = ''

  private searchFieldInputValue : string = ''

  private pageNumber : number = 1

  private numberOfTotalResults : number = 0

  private showLoadingAnimation : boolean = false

  private searchResults : TTeaserSearchResult[] = []

  private facets : IFacetResult = { filterIndustry: [], filterServiceObject: [], filterServiceDivision: [] }

  private smartSearchService! : () => Promise<SmartSearch>

  private selectedServiceDivision : string = ''

  private selectedServiceObject : string = ''

  private selectedIndustry : string = ''

  private resetFilters : boolean = false

  private optionsFilled : boolean = false

  created () {
    const { SMART_SEARCH_HOST: host, PREPARED_SEARCH_SERVICE_OVERVIEW: preparedSearch } = this.$config
    this.smartSearchService = getSmartSearchInstance({ host, preparedSearch })

    this.searchTerm = this.urlSearchTerm
  }

  async mounted () {
    // This approach works because the vue router does not trigger the window popstate
    window.addEventListener('popstate', this.onPopstate)

    // not sure if required just to be on the safe side
    this.$nextTick(() => {
      this.optionsFilled = true
    })
  }

  private async searchByUserAction (searchTermParam : string) : Promise<void> {
    (document?.activeElement as HTMLElement)?.blur()
    this.setURLSearchParameters(searchTermParam)
    return this.search(searchTermParam)
  }

  private async search (searchTermParam : string) : Promise<void> {
    this.resetFilters = false
    this.searchTerm = searchTermParam.trim() || '*'
    this.pageNumber = 1
    this.showLoadingAnimation = true

    const results = await searchByFacets(await this.smartSearchService(), this.searchMapping, this.facetMapping, this.searchParams)

    this.showLoadingAnimation = false
    this.numberOfTotalResults = results.totalNumberOfResults
    this.facets = results.facets
    this.searchResults = results.searchResults
  }

  private async loadMoreSearchResults () : Promise<void> {
    this.resetFilters = false
    this.pageNumber += 1
    this.showLoadingAnimation = true

    const results = await searchByFacets(await this.smartSearchService(), this.searchMapping, this.facetMapping, this.searchParams)

    this.showLoadingAnimation = false
    this.numberOfTotalResults = results.totalNumberOfResults
    this.facets = results.facets
    this.searchResults.push(...results.searchResults)
  }

  private async resetButtonClicked () : Promise<void> {
    this.pageNumber = 1
    this.searchTerm = ''
    this.numberOfTotalResults = 0
    this.searchResults = []
    this.resetFilters = true
    setURLParameterValue('q', undefined)
    setURLParameterValue('service-object', undefined)
    setURLParameterValue('service-division', undefined)
    setURLParameterValue('industry', undefined)
    await this.$nextTick() // otherwise watcher which depends on resetFilters will not be triggered
    await this.search('')
  }

  private setURLSearchParameters (searchTermParam : string) : void {
    setURLParameterValue('q', searchTermParam || undefined)
    setURLParameterValue('service-object', this.selectedServiceObject || undefined)
    setURLParameterValue('service-division', this.selectedServiceDivision || undefined)
    setURLParameterValue('industry', this.selectedIndustry || undefined)
  }

  beforeDestroy () {
    window.removeEventListener('popstate', this.onPopstate)
  }

  private get resetButtonVisible () : boolean {
    return !!this.inputFieldSearchTerm || !!this.selectedServiceObject || !!this.selectedServiceDivision
      || !!this.selectedIndustry
  }

  private get urlSearchTerm () : string {
    return getURLParameterValue('q')
  }

  /**
   * This will be called when:
   * - user changes browser history by clicking back or forward button
   */
  private onPopstate () : void {
    if (this.urlSearchTerm) {
      this.search(this.urlSearchTerm)
    } else {
      this.resetButtonClicked()
    }
  }

  private uniqueFilterOptions (values : string[]) : IOption[] {
    return (values || [])
      .filter((value, index, array) => array.indexOf(value) === index) // filter out duplicates, there shouldn't be any
      .map((val : string) => ({ value: val, label: val })) // map to IOption
  }

  /**
   * This value will be shown in the users input field.
   * Can be used to prevent certain values from being shown.
   */
  private get inputFieldSearchTerm () : string {
    return this.searchTerm !== '*' ? this.searchTerm : ''
  }

  private get industryOptions () : IOption[] {
    return this.uniqueFilterOptions(this.facets.filterIndustry)
  }

  private get serviceObjectOptions () : IOption[] {
    return this.uniqueFilterOptions(this.facets.filterServiceObject)
  }

  private get serviceDivisionOptions () : IOption[] {
    return this.uniqueFilterOptions(this.facets.filterServiceDivision)
  }

  private selectServiceDivision (value : string) : void {
    this.selectedServiceDivision = value
    this.searchTerm = this.searchFieldInputValue
    this.setURLSearchParameters(this.searchTerm)
  }

  private selectServiceObject (value : string) : void {
    this.selectedServiceObject = value
    this.searchTerm = this.searchFieldInputValue
    this.setURLSearchParameters(this.searchTerm)
  }

  private selectIndustry (value : string) : void {
    this.selectedIndustry = value
    this.searchTerm = this.searchFieldInputValue
    this.setURLSearchParameters(this.searchTerm)
  }

  private get targetGroup () : TTargetGroup {
    return `${this.payload.st_target_group?.value === 'B2C' ? 'B2C' : 'B2B'}`
  }

  private get showIndustryFilter () : boolean {
    return this.payload.st_show_industry_filter !== false
  }

  private get searchMapping () : TSmartSearchMapping<TTeaserSearchResult> {
    return {
      link: { key: '' }, // key value is not important
      headline: { key: 'FS_pt_headline' },
      text: { key: 'FS_pt_service___tt_teaser_text' },
      image: { key: 'FS_pt_service___tt_teaser_image' },
      imageDecorative: { key: 'FS_pt_service___tt_teaser_image_decorative' },
      imageAlt: { key: 'FS_pt_service___tt_teaser_image_alt_text' },
      templateUid: { key: 'FS_pt_service___schema_uid_facet_string' },
    }
  }

  private get facetMapping () : TSmartSearchFacetMapping<IFacetResult> {
    return {
      filterIndustry: 'FS_pt_filter_industry',
      filterServiceObject: 'FS_pt_filter_service_object',
      filterServiceDivision: 'FS_pt_filter_service_division',
    }
  }

  private get searchParams () : TSmartSearchParams {
    return {
      searchTerm: this.searchTerm,
      locale: this.locale,
      pageNumber: this.pageNumber,
      pageSize: 12,
      customParams: this.customParams,
    }
  }

  private get customParams () : [string, string][] {
    return [
      ['facet.filter.FS_project_uuid', this.$config.FSXA_PROJECT_ID], // always include!
      ['facet.filter.FS_template_uid', 'pt_service_page'],
      ['facet.filter.FS_pt_target_group', this.targetGroup],
      ['facet.filter.FS_pt_filter_service_object', this.selectedServiceObject],
      ['facet.filter.FS_pt_filter_service_division', this.selectedServiceDivision],
      ['facet.filter.FS_pt_filter_industry', this.selectedIndustry],
    ]
  }

  private inputValueChanged (value : string) : void {
    this.searchFieldInputValue = value
  }
}
