
import { ProjectProperties } from 'fsxa-api'
import { Component, InjectReactive, Vue } from 'nuxt-property-decorator'
import IImage from '../shared/general/interfaces/IImage'
import BasePicture from './base/BasePicture.vue'
import { mapImage } from '../shared/general/services/ImageService'

@Component({
  name: 'MapsReplacer',
  components: { BasePicture },
})
export default class MapsReplacer extends Vue {
  @InjectReactive({ from: 'globalSettings' }) gs! : ProjectProperties | null

  private get image () : IImage {
    return mapImage(this.gs?.data?.ps_country_project_settings?.pt_map_blocked_image)
  }
}
