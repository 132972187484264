
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Option } from 'fsxa-api'
import { ILocationTypeData, ILocationTypeFilterData } from '../../../shared/fsxa/interfaces/ILocationData'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TLocationSearchView } from '../../../shared/fsxa/types/TView'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'
import LocationSearch from '../../LocationSearch/LocationSearch.vue'
import IDropdownOption from '../../../shared/general/interfaces/IDropdownOption'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import MapsReplacer from '../../MapsReplacer.vue'
import CookieLayer from '../../CookieLayer.vue'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { setOne } from '../../../shared/general/services/privacy/PrivacySettingsService'
import { TLocationCta } from '../../../shared/general/types/TLocationCta'

interface IPayload {
  st_location_section_view_remote ?: TRemoteDatasetIndex
  st_location_type_for_commercial_vehicles_remote ?: TRemoteDatasetIndex
  st_location_types_remote ?: TRemoteDatasetIndex
  st_locations_without_type ?: boolean
  st_location_tags_remote ?: TRemoteDatasetIndex
  st_primary_cta ?: Option
  st_other_cta_1 ?: Option
  st_other_cta_2 ?: Option
}

@Component({
  name: 'StLocationSearch',
  components: {
    BaseGridLayout,
    MapsReplacer,
    CookieLayer,
    LocationSearch,
  },
})
export default class StLocationSearch extends FSXABaseSection<IPayload> {
  private remoteDatasetLoaded = false

  async mounted () {
    await this.fetchRemoteDatasets()
    this.remoteDatasetLoaded = true
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationTagsRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationTypesRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationSectionViewRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationTypeForCommercialVehiclesRemoteDatasetIndex),
    ])
  }

  private get locationTagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_tags_remote
  }

  private get locationTypesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_types_remote
  }

  private get locationSectionViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_section_view_remote
  }

  private get locationTypeForCommercialVehiclesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_type_for_commercial_vehicles_remote
  }

  private get locationTagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.locationTagsRemoteDatasetIndex)
  }

  private get locationTypesRemote () : ILocationTypeData[] {
    return getRemoteDatasetsFromStore(this.locationTypesRemoteDatasetIndex)
  }

  private get locationSectionViewRemote () : TLocationSearchView | undefined {
    return getRemoteDatasetsFromStore(this.locationSectionViewRemoteDatasetIndex)[0]
  }

  private get locationTypeForCommercialVehiclesRemote () : ILocationTypeData[] {
    return getRemoteDatasetsFromStore(this.locationTypeForCommercialVehiclesRemoteDatasetIndex)
  }

  private get noVideoAcceptLabel () : string {
    return globalLabelAsString('accept_cookies')
  }

  private get locationCta () : TLocationCta {
    return { primary: this.payload.st_primary_cta, secondary: this.payload.st_other_cta_1, tertiary: this.payload.st_other_cta_2 }
  }

  private acceptCookies () : void {
    setOne('google_maps', true)
  }

  private get preFilters () : ILocationTypeFilterData[] {
    return this.locationTypesRemote.map((type) => ({
      id: type.id,
      label: type.data.tt_name,
      value: type.data.tt_key,
    }))
  }

  private get hasOnePreFilter () : boolean {
    return this.preFilters.length === 1
  }

  private get showLocationsWithoutType () : boolean {
    return !!this.payload.st_locations_without_type
  }

  private get locationTypeForCommercialVehicles () : string | undefined {
    return this.locationTypeForCommercialVehiclesRemote[0]?.data?.tt_key?.length
      ? this.locationTypeForCommercialVehiclesRemote[0].data.tt_key
      : undefined
  }

  private get cookiesAccepted () : boolean {
    return this.$store.state.PrivacySettings.settings.google_maps
  }

  private get tagFilters () : string[] {
    return this.locationTagsRemote.map((tag) => tag?.data?.tt_name || '').filter((tagName) => !!tagName) || []
  }

  private get preSelectedDropdownOption () : IDropdownOption | undefined {
    if (this.preFilters.length !== 1) return undefined
    // they are basically the same type but using IDropdownOption as name in LocationService would be strange
    return {
      id: this.preFilters[0].id,
      label: this.preFilters[0].label,
      value: this.preFilters[0].value,
    }
  }
}
