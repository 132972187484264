
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import BaseButton from '../base/BaseButton.vue'
import BaseIcon from '../base/BaseIcon.vue'
import BaseIconButton from '../base/BaseIconButton.vue'

@Component({
  name: 'TopBar',
  components: {
    BaseButton,
    BaseIcon,
    BaseIconButton,
  },
})
export default class TopBar extends Vue {
  @Prop({ default: '' }) headline! : string

  @Prop({ default: '' }) backButtonLabel! : string

  @Prop({ default: false }) showBookmarkButton! : boolean

  @Prop({ default: false }) showFilterIconLabel! : boolean

  @Prop({ default: false }) isBookmarked! : boolean

  @Prop({ default: false }) showBackButton! : boolean

  @Prop({ default: true }) showCloseButton! : boolean

  @Prop({ default: false }) showHandle! : boolean

  private get filterLabel () : string {
    return globalLabelAsString('filters_label')
  }

  private buttonVariant (variant : string) : EButtonVariant {
    return EButtonVariant[variant]
  }

  @Emit('back')
  private back () : boolean {
    return true
  }

  @Emit('bookmark')
  private bookmark () : boolean {
    return true
  }

  @Emit('close')
  private close () : boolean {
    return true
  }

  @Emit('click')
  private click () : boolean {
    return true
  }
}
