
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { SmartSearch } from 'smartsearch.js'
import { ITeaserService } from '../../../shared/general/interfaces/ITeaserService'
import { TTargetGroup } from '../../../shared/general/types/TTargetGroup'
import TeaserServiceOverview from '../../teaser/service/TeaserServiceOverview.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { mapImage } from '../../../shared/general/services/ImageService'
import {
  search as smartSearch,
  getSmartSearchInstance,
  TSmartSearchMapping,
  TSmartSearchParams,
} from '../../../shared/general/services/SmartSearchService'
import getTeaserImage, { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import IServiceData from '../../../shared/fsxa/interfaces/IServiceData'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'

interface IPayload {
  st_headline ?: string
  st_number_of_rows ?: {
    value ?: '1' | '2' | '3'
  }
  st_load_more_button ?: boolean
  st_target_group ?: {
    key ?: TTargetGroup
  }
  st_filter_industry_remote ?: TRemoteDatasetIndex
  st_filter_service_division_remote ?: TRemoteDatasetIndex
  st_filter_service_object_remote ?: TRemoteDatasetIndex
  st_global_services_remote ?: TRemoteDatasetIndex
  st_local_services ?: IServiceData[]
}

interface ISearchResult {
  headline : string
  text : string
  link : string
  image : string
  imageDecorative : string
  imageAlt : string
  schemaUid : string
  serviceGid : string
}

@Component({
  name: 'StTeaserService',
  components: {
    BaseGridLayout,
    TeaserServiceOverview,
  },
})
export default class StTeaserService extends FSXABaseSection<IPayload> {
  private links : Record<string, string> = {}

  private smartSearchService! : () => Promise<SmartSearch>

  private dynamicTeaserServices : ITeaserService[] = []

  private remoteDatasetLoaded = false

  async created () {
    const { SMART_SEARCH_HOST: host, PREPARED_SEARCH_SERVICE_OVERVIEW: preparedSearch } = this.$config
    this.smartSearchService = getSmartSearchInstance({ host, preparedSearch })
  }

  async mounted () {
    await this.fetchRemoteDatasets()
    this.remoteDatasetLoaded = true
    this.loadTeasers()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.filterServiceObjectDatasetIndex),
      getOrFetchRemoteDatasets(this.filterServiceDivisionDatasetIndex),
      getOrFetchRemoteDatasets(this.filterIndustryDatasetIndex),
      getOrFetchRemoteDatasets(this.globalServiceDatasetIndex),
    ])
  }

  private async loadTeasers () : Promise<void> {
    const ids : string[] = []
    const filterServiceDivision : string[] = []
    const filterIndustry : string[] = []
    const filterServiceObject : string[] = []
    const targetGroup : string[] = this.targetGroup ? [this.targetGroup] : []
    // when a target group is selected there is no possibility for manual selected teasers
    switch (this.targetGroup) {
      case 'B2B':
        filterServiceDivision.push(...this.filterServiceDivision)
        filterIndustry.push(...this.filterIndustry)
        break
      case 'B2C':
        filterServiceObject.push(...this.filterServiceObject)
        break
      default:
        ids.push(...this.originalPayloadServices.filter((service) => service.id).map((service) => service.id!))
        break
    }

    const customParams : [string, string][] = [
      ['facet.filter.FS_project_uuid', this.$config.FSXA_PROJECT_ID], // always include!
      ['facet.filter.FS_template_uid', 'pt_service_page'],
      ...targetGroup.map<[string, string]>((value) => (['facet.filter.FS_pt_target_group', value])),
      ...ids.map<[string, string]>((id) => (['facet.filter.FS_pt_service_gid', id])),
      ...filterServiceDivision.map<[string, string]>((filterValue) => (['facet.filter.FS_pt_filter_service_division', filterValue])),
      ...filterIndustry.map<[string, string]>((filterValue) => (['facet.filter.FS_pt_filter_industry', filterValue])),
      ...filterServiceObject.map<[string, string]>((filterValue) => (['facet.filter.FS_pt_filter_service_object', filterValue])),
    ]

    const searchParams : TSmartSearchParams = {
      searchTerm: '*',
      locale: this.locale,
      pageNumber: 1,
      pageSize: ids.length < 100 ? ids.length : 100, // whoever uses more than 100 manual services must be insane
      customParams,
    }

    const { searchResults } = await smartSearch(await this.smartSearchService(), this.searchMapping, searchParams)

    if (this.targetGroup) {
      this.mapDynamicResults(searchResults)
    } else {
      this.collectLinksForManualTeasers(searchResults)
    }
  }

  private mapDynamicResults (searchResults : ISearchResult[]) : void {
    const smartSearchMediaHost : ISmartSearchMediaHost = {
      mediaHost: this.$config.SMART_SEARCH_MEDIA_HOST,
      projectId: this.$config.FSXA_PROJECT_ID,
      remotes: this.$config.FSXA_REMOTES,
    }
    const services : ITeaserService[] = searchResults.map((searchResult : ISearchResult) => ({
      headline: searchResult.headline,
      url: searchResult.link,
      text: searchResult.text,
      // we use the schemaUid of the service because templateUid is 'pt_service_page' and does not include 'global_data' or 'local_data'
      image: getTeaserImage(smartSearchMediaHost, searchResult.image, searchResult.schemaUid, searchResult.imageAlt, searchResult.imageDecorative),
    }))
    this.dynamicTeaserServices.push(...services)
  }

  private collectLinksForManualTeasers (searchResults : ISearchResult[]) : void {
    this.links = searchResults.filter((searchResult) => !!searchResult.serviceGid)
      .reduce((links, searchResult) => ({ ...links, [searchResult.serviceGid!]: searchResult.link }), {})
  }

  private get targetGroup () : 'B2B' | 'B2C' | undefined {
    return this.payload.st_target_group?.key || undefined
  }

  private get filterIndustry () : string[] {
    return this.filterIndustryRemote.map((filter) => filter.data?.tt_name || '').filter((filterName) => !!filterName)
  }

  private get filterServiceDivision () : string[] {
    return this.filterServiceDivisionRemote.map((filter) => filter.data?.tt_name || '').filter((filterName) => !!filterName)
  }

  private get filterServiceObject () : string[] {
    return this.filterServiceObjectRemote.map((filter) => filter.data?.tt_name || '').filter((filterName) => !!filterName)
  }

  private get searchMapping () : TSmartSearchMapping<ISearchResult> {
    return {
      headline: { key: 'FS_pt_headline' },
      text: { key: 'FS_pt_description' },
      link: { key: '' },
      image: { key: 'FS_pt_service___tt_teaser_image' },
      imageDecorative: { key: 'FS_pt_service___tt_teaser_image_decorative' },
      imageAlt: { key: 'FS_pt_service___tt_teaser_image_alt_text' },
      schemaUid: { key: 'FS_pt_service___schema_uid_facet_string' },
      serviceGid: { key: 'FS_pt_service_gid_facet_string' },
    }
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get originalPayloadServices () : IServiceData[] {
    const globalServices = this.globalServiceListRemote || []
    const localServices = this.payload.st_local_services || []
    return [...globalServices, ...localServices]
  }

  private get mappedTeaserServices () : ITeaserService[] {
    if (this.targetGroup) return this.dynamicTeaserServices
    return this.mapPayloadTeaserServices(this.originalPayloadServices)
  }

  private get batchSize () : number | undefined {
    switch (this.payload.st_number_of_rows?.value) {
      case '1':
        return 4
      case '2':
        return 8
      case '3':
        return 12
      default:
        return undefined
    }
  }

  private get showLoadMoreButton () : boolean {
    return this.payload.st_load_more_button !== false
  }

  private get filterServiceObjectDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_filter_service_object_remote
  }

  private get filterServiceDivisionDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_filter_service_division_remote
  }

  private get filterIndustryDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_filter_industry_remote
  }

  private get globalServiceDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_global_services_remote
  }

  private get filterServiceObjectRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterServiceObjectDatasetIndex)
  }

  private get filterServiceDivisionRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterServiceDivisionDatasetIndex)
  }

  private get filterIndustryRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterIndustryDatasetIndex)
  }

  private get globalServiceListRemote () : IServiceData[] {
    return getRemoteDatasetsFromStore(this.globalServiceDatasetIndex)
  }

  private mapPayloadTeaserServices (services : IServiceData[]) : ITeaserService[] {
    if (!services) return []

    return services
      .filter((service) => service.id && service.data?.tt_name)
      .map((service) => ({
        url: this.links[service.id!],
        headline: service.data!.tt_name!,
        text: service.data!.tt_teaser_text,
        image: mapImage(
          service.data!.tt_teaser_image,
          service.data!.tt_teaser_image_alt_text,
          service.data!.tt_teaser_image_alt_text,
          service.data!.tt_teaser_image_decorative,
        ),
      }))
      .filter((service) => service.url)
  }
}
