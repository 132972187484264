
import Vue from 'vue'
import {
  Component, Emit, Prop, Watch,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import Map from './Map.vue'
import {
  TGeoCoderResult, TLatLngBoundsLiteral, TLocation, TAddress,
} from '../../shared/general/types/Map'
import { setupAutocomplete } from '../../shared/fsxa/services/LocationService'
import LocationCard from './LocationCard.vue'
import ContactElements from '../contact/ContactElements.vue'
import TopBar from './TopBar.vue'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'
import BaseButton from '../base/BaseButton.vue'
import BaseInput from '../base/form/BaseInput.vue'
import BaseRadio from '../base/form/BaseRadio.vue'
import BaseCheckbox from '../base/form/BaseCheckbox.vue'
import Filters from '../Filters.vue'
import BasePill from '../base/BasePill.vue'
import IFilterElement from '../../shared/general/interfaces/IFilterElement'
import IDropdownOption from '../../shared/general/interfaces/IDropdownOption'
import fsxaProxyApiRemote from '../../shared/fsxa/services/FsxaProxyApiRemote'
import { ILocationTypeData } from '../../shared/fsxa/interfaces/ILocationData'
import { Logger } from '../../shared/general/logger/Logger'
import { TPreFilter } from '../../shared/general/types/TPreFilter'
import IContactLocation from '../../shared/fsxa/interfaces/IContactLocation'
import { nuxt } from '../../shared/general/logger/LogKey'
import { TLocationCta } from '../../shared/general/types/TLocationCta'
import GlobalLabelWrapper from '../GlobalLabelWrapper.vue'
import DeviceLocationLink from './DeviceLocationLink.vue'
import LocationTypeFilters from './LocationTypeFilters.vue'

@Component({
  name: 'LocationSearchMap',
  components: {
    LocationTypeFilters,
    DeviceLocationLink,
    GlobalLabelWrapper,
    BasePill,
    Filters,
    BaseInput,
    BaseButton,
    BaseRadio,
    BaseCheckbox,
    TopBar,
    ContactElements,
    LocationCard,
    Map,
  },
})
export default class LocationSearchMap extends Vue {
  /**
   * Small view for use in mobile and inside sidebar
   */
  @Prop({ default: false }) smallView! : boolean

  @Prop({ default: false }) inSidebar! : boolean

  @Prop({ default: () => [] }) selectedFilters! : TPreFilter[]

  @Prop({ default: () => [] }) allFilters! : IFilterElement[]

  @Prop({ default: () => ({ id: '', label: '', value: '' }) }) currentlySelectedFilter! : IDropdownOption

  @Prop() searchedLocation ?: TGeoCoderResult

  @Prop() locationTypeForCommercialVehicles ?: string

  @Prop({ required: true }) searchTermInitial! : string

  @Prop({ required: true }) mapOpen! : boolean

  @Prop({ required: true }) commercialVehicleInitial! : boolean

  @Prop({ required: true }) searchError! : string

  @Prop({ required: true }) countryCode! : string

  @Prop({ required: true }) loadLocations! : Function

  @Prop({ required: true }) changeSelectedFilter! : Function

  @Prop() locationCta ?: TLocationCta

  $refs! : {
    'location-map' : Map
    searchInput : BaseInput
  }

  /**
   * Show results on mobile view.
   */
  private showResults : boolean = false

  private gettingDeviceLocation = false

  private addresses : TAddress[] = []

  private markers : TLocation[] = []

  private selectedId : string = ''

  private showDetails : boolean = false

  private searchTerm : string = ''

  private lastBounds ?: TLatLngBoundsLiteral

  private showFilters : boolean = false

  private currentlyChosenFilter : string = this.currentlySelectedFilter.value ?? ''

  private commercialVehicle : boolean = this.commercialVehicleInitial

  private isCurrentDetailBookmarked : boolean = false

  private initialSearchDone : boolean = false

  private emptyAddress : TAddress = {
    location: [],
    detail: [],
    id: '',
  }

  mounted () {
    this.searchTerm = this.searchTermInitial

    this.$nextTick(() => {
      // Setup autocomplete
      const searchElement = this.$refs.searchInput?.$el.querySelector('input')
      if (!searchElement) return
      setupAutocomplete(searchElement, this.countryCode, this.changeSearchTerm)
    })
  }

  private get selectedAddress () : TAddress {
    return this.addresses.find((address) => address.id === this.selectedId) ?? this.emptyAddress
  }

  private async load (bounds ?: TLatLngBoundsLiteral, initialSearch ?: boolean) {
    if (!bounds) return

    this.showDetails = false

    this.lastBounds = bounds
    const [markers, addresses] = await this.loadLocations(bounds, !!initialSearch)

    this.markers = markers
    this.addresses = addresses
    this.initialSearchDone = true
  }

  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  @Watch('selectedId')
  private async locationIsBookmarked () {
    const locationType = this.$store.state.ToolbarElements.locationSidebarType?.value
    const savedLocationId = await this.$store.dispatch('Locations/getSavedLocationId', locationType)
    this.isCurrentDetailBookmarked = savedLocationId === this.selectedId
  }

  private async saveOrDeleteLocation (locationId : string, locationTypes : string[], actionType : 'save' | 'delete') : Promise<void> {
    if (!locationTypes.length) return

    const actionString : string = actionType === 'delete' ? 'Locations/deleteLocation' : 'Locations/saveLocation'
    const payload : Function = (locationType : string) => (actionType === 'delete' ? locationType : { locationId, locationType })

    const locationActions = await Promise.all(
      locationTypes.map((locationType) => this.$store.dispatch(actionString, payload(locationType))),
    )

    if (locationActions.every((action) => action)) {
      this.$store.commit('Snackbar/set', globalLabel(
        actionType === 'delete' ? 'snackbar_location_removed_label' : 'snackbar_location_saved_label',
      ))
    }
  }

  private async bookmarkLocation (address : TAddress) {
    const { id } = address
    try {
      const locationType : string = this.$store.state.ToolbarElements.locationSidebarType?.value
      const locationTypes : string[] = (await fsxaProxyApiRemote.fetchElement({
        id,
        locale: this.$store.state.Locale.fsxaLocale,
      }))?.data?.tt_location_types?.map((type : ILocationTypeData) => type?.data?.tt_key || '').filter(Boolean) || []

      if (await this.$store.dispatch('Locations/getSavedLocationId', locationType) === id) {
        await this.saveOrDeleteLocation(id, locationTypes, 'delete')
      } else {
        await this.saveOrDeleteLocation(id, locationTypes, 'save')
      }
    } catch (error) {
      Logger.warn(nuxt, `Could not bookmark selected location with id ${id}`, error)
    }
  }

  private async retrieveDeviceLocation (deviceLocation : TGeoCoderResult | null) {
    if (!deviceLocation) return

    this.gettingDeviceLocation = true
    this.changeSearchTerm(this.deviceLocationLabel)
  }

  private setCurrentlySelected (id : string) {
    this.selectedId = id
    this.$refs['location-map'].panToId(id)
  }

  private locationClick (id : string) {
    this.setCurrentlySelected(id)
  }

  private detailClick (id : string) {
    this.setCurrentlySelected(id)
    this.showDetails = true
  }

  private markerClick (id : string) {
    this.locationClick(id)
    this.showResults = true
    this.$el.querySelector(`[data-location-id="${id}"]`)?.scrollIntoView({ behavior: 'smooth' })
  }

  private loadFromBounds (bounds : TLatLngBoundsLiteral, initialSearch : boolean) {
    this.load(bounds, initialSearch)
  }

  private updateFilters (selected ?: TPreFilter) {
    this.currentlyChosenFilter = selected?.filterValue || ''
    this.commercialVehicle = selected?.commercialVehicles || false
  }

  private changeFilters (remove : boolean) {
    this.showFilters = false
    this.showDetails = false

    if (remove) {
      this.changeSelectedFilter({ filterValue: '', commercialVehicles: false })
      this.currentlyChosenFilter = ''
    } else {
      this.changeSelectedFilter({ filterValue: this.currentlyChosenFilter, commercialVehicles: this.commercialVehicle })
    }
    this.load(this.lastBounds, false)
  }

  private toggleFilters () {
    this.showFilters = !this.showFilters
  }

  private showDetailsButtonForAddress (address : TAddress) : boolean {
    const { detail } = address

    // We check if we want to hide the details button and return the opposite because "showDetailsButton"
    const hide = detail
      .filter((one) => !['linkOnlineAppointment', 'linkWebsite', 'linkRoutePlanner', 'linkWhgCertificates'].includes(one.type))
      .every(
      // Hide if every element either has undefined data, or has data with empty opening hours
        ({ data }) => !data || (data as IContactLocation).openingHours?.length === 0,
      )

    return !hide
  }

  private get detailsOpenedClasses () {
    if (this.smallView) {
      return this.showDetails ? 'translate-x-0' : 'translate-x-full'
    }

    return this.showDetails ? 'translate-x-full ml-6' : 'translate-x-0'
  }

  private get postcodeCityLabel () : string {
    return globalLabelAsString('postcode_city_label')
  }

  private get searchButtonLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get listLabel () : string {
    return globalLabelAsString('list_label')
  }

  private get mapLabel () : string {
    return globalLabelAsString('map_label')
  }

  private get resultsLabel () : string {
    return globalLabelAsString('results_label')
  }

  private get filterLabel () : string {
    return globalLabelAsString('filters_label')
  }

  private get headlineLabel () : string {
    return globalLabelAsString('location_search_headline_label')
  }

  private get showResultsLabel () : string {
    return globalLabelAsString('show_results_label')
  }

  private get suitableForCommercialVehiclesLabel () : string {
    return globalLabelAsString('suitable_for_commercial_vehicles_label')
  }

  private get locationSearchNoResultsLabel () : RichTextElement[] {
    return globalLabel('location_search_no_results_label') as RichTextElement[]
  }

  private get selectedFilter () : TPreFilter {
    return {
      filterValue: this.currentlyChosenFilter || '',
      commercialVehicles: this.commercialVehicle,
    }
  }

  private get deviceLocationLabel () : string {
    return globalLabelAsString('device_location_label')
  }

  private closeResultDetail () : void {
    this.closeSidebar()
    this.showDetails = false
    this.showResults = false
  }

  private closeMobileResultList () : void {
    this.closeSidebar()
    this.showResults = false
    this.showDetails = false
  }

  private closeMobileHeader () : void {
    this.closeSidebar()
    this.showResults = false
    this.showDetails = false
    if (this.showFilters) this.showFilters = false
    else this.closeMap()
  }

  private closeSidebar () {
    this.$store.commit('Sidebar/set', false)
  }

  @Watch('searchError')
  @Watch('searchedLocation')
  private onSearchedLocationChange () {
    this.gettingDeviceLocation = false
  }

  @Watch('searchTermInitial')
  private onSearchTermInitialChange () {
    this.searchTerm = this.searchTermInitial
  }

  @Watch('commercialVehicleInitial')
  private onCommercialVehicleInitialChange () {
    this.commercialVehicle = this.commercialVehicleInitial
  }

  @Emit('search-term-changed')
  private changeSearchTerm (searchTerm : string) : string {
    return searchTerm
  }

  @Emit('close-map')
  private closeMap () : true {
    return true
  }
}
