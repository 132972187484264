
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import ILinkData from '../../../shared/fsxa/interfaces/ILinkData'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

interface IPayload {
  st_fontawesome_icon_remote ?: TRemoteDatasetIndex
  st_link ?: ILinkData
}

@Component({
  name: 'StCta',
  components: {
    BaseCta: () => import('../../base/BaseCta.vue'),
    BaseGridLayout,
  },
})
export default class StCta extends FSXABaseSection<IPayload> {
  private remoteDatasetLoaded = false

  async mounted () {
    await this.fetchRemoteDatasets()
    this.remoteDatasetLoaded = true
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.fontawesomeIconRemote)
  }

  private get iconNameRemote () : string {
    return getRemoteDatasetsFromStore(this.fontawesomeIconRemote)[0]?.data?.tt_fontawesome_v6_id || ''
  }

  private get fontawesomeIconRemote () : TRemoteDatasetIndex | undefined {
    return this.payload.st_fontawesome_icon_remote
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get cta () : ILink | undefined {
    return {
      ...getLinkObject(this.payload.st_link, this.getUrlByPageId),
      iconName: this.iconNameRemote,
    }
  }
}
