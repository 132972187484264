
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'

interface IPayload {
  st_fontawesome_icon_remote ?: TRemoteDatasetIndex
  st_icon_designation ?: string
  st_info_text ?: string
}

@Component({
  name: 'StIconCarouselElement',
  components: { IconCarouselElement: () => import('../../iconCarousel/IconCarouselElement.vue') },
})
export default class StIconCarouselElement extends FSXABaseSection<IPayload> {
  private remoteDatasetLoaded = false

  async mounted () {
    await this.fetchRemoteDatasets()
    this.remoteDatasetLoaded = true
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.fontawesomeIconRemote)
  }

  private get iconNameRemote () : string {
    return getRemoteDatasetsFromStore(this.fontawesomeIconRemote)[0]?.data?.tt_fontawesome_v6_id || ''
  }

  private get fontawesomeIconRemote () : TRemoteDatasetIndex | undefined {
    return this.payload.st_fontawesome_icon_remote
  }

  private get headline () : string | undefined {
    return this.payload.st_icon_designation
  }

  private get text () : string | undefined {
    return this.payload.st_info_text
  }
}
