/**
 * Creates a throttled version of the provided function that will only execute
 * at most once in the specified time limit. If the function is called multiple
 * times within the limit, it will be executed only once.
 *
 * @param {function(...unknown[]): void} func - The function to throttle.
 * @param {number} limit - The time limit in milliseconds for throttling.
 * @returns {function(...unknown[]): void} A throttled version of the input function.
 */
export default (func : (...args : unknown[]) => void, limit : number) : (...args : unknown[]) => void => {
  let lastFunc : ReturnType<typeof setTimeout>
  let lastRan : number

  return (...args : unknown[]) => {
    if (!lastRan) {
      func(...args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func(...args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}
